export default {
  tagline: "Nous allons vous faire adorer la télé !",
  modify: "modifier",
  my_account: "Mon compte",
  backToHome: "Retour à l'accueil",
  helper_center: "Centre d'aide",
  login: "Se connecter",
  logout: "Se déconnecter",
  press: "Presse",
  pressRelease: "Press releases (en)",
  helpOnline: "Aide en ligne",
  my_bookmarks: "Mes enregistrements",
  my_bookmarks_scheduled: "Mes enregistrements à venir",
  genericErrorMessage: "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
  job_offers: "Join the team",
  blog: "Blog",
  about: "À propos",
  support: "support",
  update: "Mettre à jour",
  legal_notice: "Mentions légales",
  privacyPolicy: "Politique de confidentialité",
  cookies: "Utilisation des cookies",
  termsOfUse: "Conditions d’utilisation",
  mentions: "Mentions légales",
  applications: "Applications",
  download: "Télécharger",
  application: "l'application",
  save: "Enregistrer",
  send: "Envoyer",
  soon: "Bientôt disponible",
  type: "Type",
  category: "Catégorie",
  title: "Titre",
  channel: "Chaîne",
  duration: "Durée",
  broadcast: "Diffusion",
  broadcasted: "Diffusé",
  watched: "Vu",
  delete: "Supprimer",
  cancel: "Annuler",
  date: "Date",
  at: "à",
  in: "dans",
  daySingular: "jour",
  days: "jours",
  hour: "heure",
  hours: "heures",
  left: "plus que",
  today: "Aujourd'hui",
  tomorrow: "Demain",
  yesterday: "Hier",
  day_after_tomorrow: "Après-demain",
  day_before_yesterday: "Avant-hier",
  minute_long: "minute",
  minutes_long: "minutes",
  not_broadcasted: "Non disponible en ce moment",
  service_period: "Période de service",
  payment_mode: "Mode de paiement",
  payment_status: "Statut",
  amount: "Montant",
  email: "Adresse électronique",
  buyChromecast: "Acheter mon Chromecast",
  subscribe: "Souscrire",
  seeOffers: "Voir l'offre",
  subscribe_free_month: "Profiter de mon mois gratuit",
  unsubscribe: "Résilier",
  validate: "Valider",
  terminate: "Terminer",
  loading: "Chargement...",
  close: "Fermer",
  continue: "Continuer",
  pageTitle: {
    public: "Regarder la télé sur tous vos appareils - Molotov.tv",
    description: "L'installation de l'application Molotov est disponible sur tous vos appareils : Android, iOS, Smart TV et bien d'autres.",
    authenticated: "Molotov.tv - Espace client",
  },
  day: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  month: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  nav: {
    products: "Options et chaînes",
    profile: "Mon compte",
    storage: "Gestion des enregistrements",
    parentalControl: "Contrôle Parental",
    channels: "Chaînes",
    payment: "Informations de paiement",
    devices: "Mes appareils",
    tv: "Molotov sur mon téléviseur",
    cgu: "Conditions d'utilisation",
    privacy: "Politique de confidentialité",
    mentions: "Mentions légales",
    notifications: "Notifications",
  },
  products: {
    title: "Offres",
  },
  profile: {
    connexion: "Mes identifiants de connexion",
    mobileConnexion: "Identifiants",
    personalInfo: "Mes informations personnelles",
    mobilePersonalInfo: "Informations personnelles",
    deleteAccount: "Supprimer mon compte",
    pursue: "Poursuivre",
    email: "Adresse email",
    password: "Mot de passe",
    lastname: "Nom",
    firstname: "Prénom",
    gender: "Sexe",
    birthday: "Date de naissance",
    save: "Enregistrer",
    delete: "Supprimer mon compte",
    male: "Masculin",
    female: "Féminin",
    success: "Votre profil a été mis à jour",
    publicProfile: "Gérer votre profil public",
    publicProfileInfo: "Ces informations seront visibles des autres utilisateurs lorsque vous publierez un commentaire.",
    options: "Mes options",
    contact: "Gestion de mon compte",
  },
  devices: {
    my_devices: "Mes appareils",
    auto: "Automatique",
    basic: "Basique",
    standard: "Standard",
    high: "Élevée",
    video_quality: "Qualité de la vidéo",
    auto_quality: "La qualité de la vidéo s’ajuste automatiquement en fonction de votre connexion internet",
    basic_quality: "Consommation maximale de 0,4 Go par heure",
    standard_quality: "Consommation maximale de 0,8 Go par heure",
    high_quality: "Consommation maximale de 1,4 Go par heure",
    last_using: "Dernière utilisation",
    first_using: "Date d'association",
    remaining_devices: "appareils",
    remaining_device: "appareil",
    deleteTitle: "Supprimer l'accès d'un appareil",
    deleteMessage: "Êtes-vous sûr de vouloir supprimer l'accès à cet appareil ?",
    deleteMessageWarning: "En supprimant l'accès, vous vous déconnectez de votre compte Molotov.",
    allowedDevicesMessage: "Votre abonnement vous permet de profiter de Molotov sur ${devicesCountMax} appareils maximum.",
    currentDevicesRegistered: "Votre profil Molotov est actuellement associé aux appareils suivants :",
    noDevicesRegistered: "Aucun appareil n'est pour l'instant rattaché à votre profil.",
    downloadTexts: {
      android: "Télécharger Molotov sur Android",
      basic: "Télécharger l'App",
      chromecast: "Utilisez Chromecast avec Apple ou Android",
      freeChannels: "Télécharger pour regarder la télé",
      iphone: "Télécharger Molotov sur iPhone et iPad",
      tvDirect: "Télécharger pour regarder la télé en direct",
      tvReplay: "Télécharger pour regarder la télé en replay",
      tvStreaming: "Télécharger pour regarder la télé en streaming",
    },
  },
  storage: {
    storage_title: "Stockage",
    delete_all_bookmarks: "Supprimer tous les enregistrements",
    delete_selected_bookmarks: "Supprimer les enregistrements sélectionnés",
    increase: "Augmenter mon espace de stockage",
    discover: "Découvrir l'enregistrement",
    storage_used: "de stockage utilisé",
    scheduled_storage: "Programmes à venir",
    used: "d'enregistrement",
    illimited: "Illimité",
    scheduled: "d'enregistrement à venir",
    storage_total: "Espace de stockage total",
    filters: {
      allChannels: "Toutes les chaînes",
      available: "Disponible",
      categories: "Catégories",
      channels: "Chaînes",
      filter: "Filtrer",
      future: "A venir",
      record: "Enregistrement",
      seeAll: "Voir tout",
    },
    disable_smart_bookmark: "Désactiver l'enregistrement en série",
  },
  payment: {
    my_payment_mode: "Mon mode de paiement",
    my_payment_mode_android_tv: "Mon autre mode de paiement",
    no_payment_method: "Pas de moyen de paiement.",
    bill_detail: "Détails de la facturation",
    info: "Les frais d’abonnement sont facturés au début de chaque période.",
    invalidCardInformations: "Ces informations bancaires semblent incorrectes",
    next_bill: "Prochaine date de facturation :",
    error: "Il semblerait qu’il y ait eu un problème avec le paiement. Mettez à jour vos informations de paiements.",
    delete_payment_mode: "Supprimer ce mode de paiement",
    delete_payment_description:
      " Vous pouvez supprimer les informations relatives à un mode de paiement si vous avez un autre mode de paiement ou aucune option active.",
    editionButton: "Valider",
    editionTitle: "Mettez à jour vos informations de paiement",
    errorBuyActionDefault: "Une erreur est survenue sur cette offre, veuillez nous excuser pour la gêne occasionnée.",
    errorPaymentFormDefault:
      "Une erreur est survenue lors de la récupération de ce formulaire de paiement, veuillez nous excuser pour la gêne occasionnée.",
    errorPasswordFormDefault: "Une erreur est survenue lors de la récupération de ce formulaire, veuillez nous excuser pour la gêne occasionnée.",
    forgotPassword: "Oublié ?",
    dspBilling: "Retrouvez vos factures sur votre espace client opérateur",
    dspPaymentMethod: "Vos abonnements sont facturés via votre opérateur",
    paymentForm: {
      cardNumber: "Numéro de carte",
      cardNumberError: "Numéro de carte invalide",
      cvvTooltip: "Le code CVV désigne les 3 ou 4 chiffres situés au recto (American Express) ou au verso (MasterCard & Visa) de votre carte.",
      expiryDate: "Date d'expiration",
      expiryDateError: "Date invalide",
      securityCode: "Cryptogramme visuel",
      securityCodeError: "Cryptogramme invalide",
      securedServer: "Serveur sécurisé",
      title: "Paiement",
    },
    passwordForm: {
      password: "Mot de passe",
    },
  },
  parental_control: {
    errorPasswordFormDefault:
      "Une erreur est survenue lors de la récupération de ce formulaire de mot de passe, veuillez nous excuser pour la gêne occasionnée.",
    ratings: {
      public: "Tout public",
      ten: "Enfants",
      twelve: "Adolescents",
      sixteen: "Jeunes adultes",
      eighteen: "Adultes",
    },
    passwordForm: {
      placeholder: "Mot de passe",
      title: "Entrez votre mot de passe Molotov",
      subtitle: "Pour accéder au contrôle parental, veuillez entrer votre mot de passe de compte.",
      forgetPwd: "Mot de passe de compte oublié ?",
      confirmBtn: "Confirmer",
      createPasswordTitle: "Créer votre mot de passe Molotov",
      createPasswordSubtitle: "Pour paramétrer le contrôle parental vous devez définir un mot de passe",
    },
  },
  channels: {
    channels: "Chaînes",
    offers: "Offres",
    stockage: "Augmenter votre capacité d'enregistrement",
    changeOffer: "Changer d'offre",
    subscribe: "Souscrire",
  },
  pageChangeEmail: {
    title: "Modification de votre adresse e-mail",
    newEmail: "Votre nouvel e-mail",
    confirmEmail: "Confirmation de votre nouvel e-mail",
    password: "Votre mot de passe",
    success: "Votre adresse e-mail a correctement été modifiée. Vous pouvez dès maintenant vous connecter et profiter de Molotov.",
    connect: "Se connecter",
  },
  modalSetPassword: {
    title: "Définir un mot de passe",
    inputPlaceholder: "minimum 6 caractères",
    intro: "Pour la gestion de votre compte Molotov.tv vous devez définir un mot de passe.",
    createPassword: "Créer un mot de passe",
    password: "Mot de passe",
    passwordConfirm: "Confirmer le mot de passe",
    failed: "Votre mot de passe n’a pas pu être enregistré",
  },
  legal: {
    terms: {
      title: "Conditions générales d’utilisation",
    },
    cookies: {
      title: "Utilisation des cookies",
      success: "Vos préférences ont été mises à jour.",
    },
    privacy: {
      title: "Politique de confidentialité",
    },
    notice: {
      title: "Mentions légales",
    },
  },
  modalDeleteAccount: {
    title: "Supprimer mon compte",
    mobileTitle: "Suppression",
    needPassword:
      "Pour supprimer votre compte Molotov, vous devez créer votre mot de passe. Il vous sera demandé pour confirmer la demande de suppression.",
    message: "Votre compte Molotov sera définitivement supprimé.",
    appleOffer: "Vos abonnements pris via Apple sont à résilier dans votre compte iTunes.",
    enter_password: "Entrez votre mot de passe",
    bookmarkOffer: "Vous allez perdre vos enregistrements !",
  },
  modalDeleteBookmarks: {
    title: "Suppression de tous les enregistrements",
    message: "Attention, vous allez supprimer tous vos enregistrements.",
  },
  modalDeleteSelectedBookmarks: {
    title: "Suppression d'enregistrements",
    message: "Attention, vous allez supprimer les enregistrements selectionnés.",
    deletePending: "Suppression en cours...",
  },
  modalChangeRating: {
    title: "Modifier le contrôle parental",
    needPassword:
      "Pour accéder au contrôle parental vous devez créer votre mot de passe. Il vous sera demandé pour pourvoir paramétrer votre niveau de protection.",
    password: "Mot de passe",
    leftButton: "Annuler",
    rightButton: "Modifier",
    failed: "Votre contrôle parental n'a pas pu être mis à jour",
  },
  modalConfirm: {
    title: "Êtes-vous sûr ?",
  },
  loginPage: {
    title: "Connexion",
    password: "Mot de passe",
    forgottenPassword: "Mot de passe oublié ?",
    errorFacebookMissingEmailRetry: "Veuillez partager votre adresse e-mail.",
    completeInformations: "Complétez vos informations",
    description: "Vérifiez et complétez les informations manquantes pour accéder à toutes vos chaînes préférées.",
    noAccountLabel: "Vous n'avez pas de compte ? Créer un compte",
    oAuthNotRegistredLabel: "Pas encore inscrit ?",
    oAuthDoRegisterLabel: "Inscrivez vous",
  },
  signupPage: {
    title: "Inscription",
    openEurope: "Pour pouvoir regarder Molotov en Europe, vous devrez être abonné à l’option Molotov Plus.",
    withEmail: "avec Email",
    email: "Adresse électronique",
    emailPlaceholder: "exemple@email.com",
    password: "Mot de passe",
    passwordPlaceholder: "minimum 6 caratères",
    age: "Votre âge",
    day: "Jour",
    month: "Mois",
    year: "Année",
    gender: "Votre sexe",
    male: "Homme",
    female: "Femme",
    cgu:
      "Molotov est accessible uniquement depuis la France Métropolitaine, les DROM COM, Monaco et Andorre. En vous inscrivant, vous acceptez les ${cguConditions}, la ${cguPolitics} et l’${cguCookies} de Molotov.",
    cguConditions: "Conditions d'utilisation",
    cguPolitics: "Politique de confidentialité",
    cguCookies: "Utilisation des cookies",
    submit: "S'inscrire",
    connect: "Vous avez déjà un compte ? Se connecter",
    invalidForm: "Tous les champs n'ont pas été remplis correctement",
  },
  resetEmailPage: {
    title: "Réinitialisation de votre adresse e-mail",
    subText: "Vous allez recevoir un e-mail sur %s avec un lien pour modifier cette adresse.",
    help:
      "Si vous avez besoin d’assistance pour modifier votre adresse e-mail ou si celui-ci est incorrect et vous empêche d’avancer dans la procédure, contactez le service d’aide Molotov.",
    error: "Email inconnue",
    successText: "Un lien pour modifier votre e-mail vient de vous être envoyé sur :",
    successDescription:
      "Si dans quelques minutes vous n'avez toujours pas reçu le message, merci de vérifier que celui-ci n'est pas dans vos messages indésirables.",
    successHelp: "Si vous avez besoin d’assistance, contactez le service d’aide Molotov.",
  },
  resetPasswordPage: {
    title: "Réinitialisation de votre mot de passe",
    subText:
      "Vous allez recevoir un email sur %s avec un lien pour réinitialiser votre mot de passe. (pensez à regarder dans vos spams si vous ne le recevez pas).",
    description: "A la réinitialisation de votre mot de passe, vous devrez vous reconnecter sur tous vos appareils.",
    help:
      "Si vous avez besoin d’assistance pour modifier votre adresse e-mail ou si celui-ci est incorrect et vous empêche d’avancer dans la procédure, contactez le service d’aide Molotov.",
    error: "Email inconnue",
    successText: "Un lien pour modifier votre mot de passe vient de vous être envoyé sur :",
    successDescription:
      "Si dans quelques minutes vous n'avez toujours pas reçu le message, merci de vérifier que celui-ci n'est pas dans vos messages indésirables.",
    successHelp: "Si vous avez besoin d’assistance, contactez le service d’aide Molotov.",
    passwordSentWaiting:
      "Si dans quelques minutes vous n'avez toujours pas reçu le message, merci de vérifier que celui-ci n'est pas dans vos messages indésirables.",
  },
  changePasswordPage: {
    title: "Réinitialisation de votre mot de passe",
    newPassword: "Votre nouveau mot de passe",
    confirmPassword: "Confirmez votre mot de passe",
    passwordSent: "Votre mot de passe a correctement été réinitialisé. Vous pouvez dès maintenant vous connecter et profiter de Molotov.",
    passwordSentLogged: "Votre mot de passe a correctement été réinitialisé. Vous pouvez dès maintenant poursuivre notre navigation.",
    success: "Votre mot de passe a été mis à jour",
    minimumCharsCount: "6 caractères minimum",
    maximumCharsCount: "127 caractères maximum",
    nonIdenticalPasswords: "Les mot de passe ne sont pas identiques",
    invalidPassword: "Votre mot de passe contient des caractères non autorisés. Merci de saisir un nouveau mot de passe.",
  },
  createAccountPage: {
    createPassword: "Création du mot de passe",
    confirmPassword: "Confirmation du mot de passe",
  },
  productsPage: {
    subtitle1: "Pour profiter d’une plus large sélection de programmes, ajoutez une ou plusieurs offres selon vos goûts (comme ",
    subtitle2:
      "). Vous pouvez changer et annuler vos abonnements à tout moment directement depuis votre espace Mon compte, rubrique Options et chaînes.",
    bookmark: "150h d'enregistrement",
    bookmark_label: "dans le cloud",
    channel_hd: "HD garantie",
    channel_hd_label: "selon votre connexion",
    multi_screen: "Jusqu'à 4 écrans simultanés",
    multi_screen_label: "selon la chaîne et votre connexion",
    open_europe: "Portabilité de votre compte",
    open_europe_label: "depuis les États membres de l’U. E.",
    include_in_option: "Inclus dans toutes les options",
    title: "Choisissez votre Molotov",
    personalize: "Libre à vous d’ajouter des options.",
    add_option: "en ajoutant des options",
    automatic_renewal: "Renouvellement automatique",
    soon_available: "Bientôt disponible",
    cguApple0:
      "Votre compte iTunes est débité à la confirmation d'achat. Lors du renouvellement des options mensuelles votre compte est prélevé du même montant dans les 24 heures avant la fin de la période en cours. Ces options sont facturées via iTunes et sont renouvelées de manière automatique chaque mois.",
    cguApple1:
      "Le renouvellement automatique peut être désactivé dans les réglages du compte de l’utilisateur. Vous pouvez créer, modifier ou annuler vos abonnements quand vous le souhaitez, directement depuis votre compte iTunes. L’annulation du renouvellement automatique doit être effectuée au moins 24 heures avant la fin de la période d’abonnement en cours. Aucune résiliation n’est possible pendant la période active en cours.",
    cguApple2: "Le service Molotov est uniquement accessible depuis le territoire français.",
    cguApple3: "Plus de details dans nos conditions générales de ventes et notre politique de confidentialité http://www.molotov.tv/legal.",
    offerDisclaimer: "Offre non cumulable, valable une seule fois et pendant 30 jours.",
    cguFreeOffer: "Cette offre est sans engagement : vous pouvez annuler votre abonnement quand vous le souhaitez.",
    restorePayments: "Restaurer mes achats",
  },
  offerPage: {
    offer_not_found: "Offre inexistante.",
    not_eligible: "Désolé, vous n’êtes pas éligible à cette offre. Voir conditions générales sur |[link]|.",
  },
  paymentPage: {
    no_bills: "Pas de factures.",
  },

  productPassword: {
    title: "Confirmation",
    text: "Le moyen de paiement déjà enregistré sera utilisé pour votre nouvelle souscription.",
    retext: "Le moyen de paiement déjà enregistré sera utilisé pour votre prolongement de souscription.",
    subText: "Pour confirmer la souscription à votre offre, veuillez entrer votre mot de passe Molotov :",
    resubText: "Pour confirmer le prolongement de votre offre, veuillez entrer votre mot de passe Molotov :",
    unsubscribe: "Pour confirmer la résiliation à votre offre, veuillez entrer votre mot de passe Molotov :",
  },
  productConfirmation: {
    availableUntilDate: "Votre option est disponible jusqu’au",
  },
  productWarning: {
    title: "Attention",
    confirmMessage: "Vous vous apprêtez à résilier votre offre %s.",
    willLoseBookmarks: "Vos enregistrements sur les chaînes %s seront définitivement supprimés à l'issue de votre abonnement.",
    willLosePrograms: "Vous perdrez également l’accès aux programmes de l'option %s.",
    willReduceBookmarkCapacity: "Résilier votre offre %s diminuera votre capacité d'enregistrements.",
    cannotUnsubscribe: "L'option %s n'est pas résiliable.",
  },
  product: {
    cguAware:
      "En validant mon achat, j'accepte et je reconnais avoir pris connaissance de l'intégralité des Conditions générales de vente. Par cette validation j'accède automatiquement à mes abonnements et j'accepte de ne pas bénéficier de mon délai légal de rétractation.",
  },
  downloadPage: {
    support_message: "Si vous rencontrez un problème, n'hésitez pas à poser vos questions au ",
    customer_service: "service clients",
  },
  deepLinkPage: {
    watchNow: "Regarder maintenant",
    programUnavailableBtn: "Essayer Molotov",
    replayPageCTA: "Voir tous les replay",
  },
  hardwarePage: {
    title: "La télé qui vous suit partout.",
  },
  publicMenu: {
    african: "Bouquet Africain",
    chromecast: "Offre spéciale Chromecast",
    createAccount: "Créer un compte",
    devices: "Appareils compatibles",
    download: "Télécharger",
    offers: "Offres",
    profile: "Me connecter",
    seoHome: "Programme TV",
    movies: "Films",
    replayTv: "Replay TV",
    showsTv: "Séries",
    football2024: "Football 2024",
    football2024Footer: "Championnat d’Europe de football 2024",
    seoHomeTonight: "Programme TV ce soir",
    travel: "Molotov en Europe",
    got: "Game of Thrones",
    mytho: "Mytho sur Arte",
    gift: "Carte cadeau",
    adultSwim: "Adult Swim + WarnerTV Next",
    starzplay: "STARZPLAY",
    westworld: "Westworld sur OCS",
    series: "Films et séries en streaming",
    hotd: "House of the Dragon",
    cdm: "Coupe du monde 2022",
    twd: "The Walking Dead",
    molotovExtra: "Molotov Extra",
    molotovExtended: "Molotov Extended",
    molotovTvAwards: "Molotov TV Awards",
    emissionsTv: "Emissions TV",
    mangas: "Mangas streaming",
    regarderLaTele: "Regarder la télé",
  },
  tooltip: {
    rebroadcast: "rediffusion",
  },
  accountChoice: {
    buttonYes: "Oui, continuer",
    buttonNo: "Changer de compte",
  },
  OCSPage: {
    accountChoiceMessage: "Souhaitez-vous poursuivre votre authentification OCS avec ce compte ?",
    errorDefault: "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    subscribeButton: "Souscrire sans engagement",
    retryButton: "Réessayer",
  },
  OAuthPage: {
    buttonAllow: "Autoriser",
    buttonDeny: "Refuser",
    errorDefault: "Une erreur est survenue, veuillez nous excuser pour la gêne occasionnée.",
    invalidParams: "Le parametre ${param} est require",
    redirectionMessage: "Vous allez être redirigé vers %s...",
  },
  campaign: {
    title: "Bienvenue",
    text1: "Pour bénéficier de votre offre Exclusive",
    text2: "créez votre compte ou identifiez-vous !",
    testimonials: {
      apple: {
        text: "« Meilleure app de l’année 2016 »",
        by: "Apple",
      },
      android: {
        text: "« La télévision du futur est enfin lancée »",
        by: "Phonandroid",
      },
      other: {
        intro: "+3 000 000 d’utilisateurs en France",
        text: "« Simple et Intuitif. Convaincant »",
        by: "Télérama",
      },
    },
    download: "Téléchargez l'app",
    congratsTitle: "Félicitations",
    installText:
      "Téléchargez maintenant l'application Molotov pour regarder la télé et bénéficiez instantanément de votre offre exclusive Cdiscount.",
    sorryTitle: "Désolé",
    appFreeIntro: "Vous pouvez cependant découvrir Molotov avec:",
    appFreeList1: "34 chaînes",
    appFreeList2: "10h d'enregistrements",
    appFreeList3: "Replays",
    appFreeList4: "Retour en arrière sur le live",
    missingCode:
      "Nous n’avons pas pu récupérer votre promotion. Réessayez en faisant un copier / coller du lien complet depuis le mail que vous avez reçu.",
  },
  link: {
    code: {
      title: "Activez votre appareil",
      body: "Saisissez le code d’activation qui apparait sur votre télé.",
    },
    success: {
      title: "Vous êtes connecté",
      body:
        "Rendez-vous sur votre télé pour profiter pleinement de Molotov et télécharger l’application sur cet appareil pour une expérience enrichie.",
      footerBody: "Découvrez les offres Molotov pour profiter de films et séries en exclus !",
      footerBodyOpenEurope: "Vous devez être abonné pour pouvoir regarder Molotov en Europe.",
      footerButton: "Voir les offres",
      footerButtonOpenEurope: "Continuer",
    },
    choice: {
      message: "Souhaitez-vous poursuivre votre authentification avec ce compte ?",
    },
  },
  seoFooter: {
    home: {
      android: "TV sur Android",
      appleTv: "Application Apple TV",
      chromecast: "Chromecast Application",
      iphone: "Streaming TV iPhone",
      smartTv: "Appli Smart TV",
      lg: "Application Smart TV LG",
      desktop: "TV sur PC",
      replay: "TV Replay",
      streaming: "TV Streaming",
      free: "TV",
      direct: "TV en direct",
      series: "Toutes les séries",
    },
    program: {
      android: "Application Télévision Android",
      appleTv: "Application Apple TV",
      chromecast: "TV Cast",
      iphone: "Streaming TV iPad",
      smartTv: "Application Smart TV Samsung",
      lg: "Application Smart TV LG",
      desktop: "Regarder la TV sur ordinateur",
      replay: "TV Replay",
      streaming: "TV Streaming",
      free: "Chaîne TV",
      direct: "TV direct",
      series: "Toutes les séries",
    },
    category: {
      android: "Regarder TV sur Android",
      appleTv: "Application Apple TV",
      chromecast: "Application Chromecast",
      iphone: "Streaming TV iPhone",
      smartTv: "Appli Smart TV",
      lg: "Appli TV LG",
      desktop: "TV sur PC",
      replay: "TV Replay",
      streaming: "TV Streaming",
      free: "Télévision",
      direct: "Télé direct",
      series: "Toutes les séries",
    },
  },
  travel: {
    meta: {
      title: "La TV française dans toute l'Europe",
      description:
        "Regardez toutes les chaînes françaises de télévision depuis les pays de l'Union européenne. Disponible sur tous vos écrans pour les abonnés Molotov.",
    },
    steps: ["Créez votre compte ou identifiez-vous.", "Configurez votre moyen de paiement."],
    headerDescription:
      "C’est nouveau ! Désormais vous pouvez accéder à Molotov depuis les États membres de l’Union européenne. Activez une option dans votre compte et regardez la télévision française lors de vos déplacements.",
    descriptionList: [
      "La télé française en direct, start-over, et replay",
      "150 heures d’enregistrement dans le cloud",
      "L’accès à toutes les chaînes disponibles sur Molotov",
      "Jusqu’à 4 écrans simultanés en HD garantie*",
      "Disponible depuis 28 pays en Europe",
    ],
    countryTitle: "...accessible depuis toute l'Europe.",
    countryDescription: "Profitez de Molotov depuis les États membres de l’Union européenne.",
    offerTitle: "L’accès à Molotov depuis l’Union européenne est inclus dans toutes nos offres.",
    offerSubtitle:
      "La télé française (TF1, France 2, M6...) accessible depuis toute l'Europe pour les résidents français. À partir de 3.99€ sans engagement.",
    offerCondition: "* selon votre connexion",
    subscribe: "Découvrir",
    alreadySubscribed: "Déjà souscrit",
    channelsTitle: "Le meilleur de la télévision française...",
  },
  cookieSettings: {
    bannerMessage1:
      "<b>Fubo, Molotov et leurs partenaires avec votre consentement peuvent utiliser des cookies ou équivalent pour stocker et/ou accéder à des informations sur votre terminal afin notamment de collecter des données telles que :</b> données de navigation (comme les pages et applications consultées), identifiants (comme l’adresse IP, numéro d’abonnement Molotov, identifiants mobiles publicitaires)... Les traitements de certains partenaires reposent sur leurs intérêts légitimes. Vous pouvez vous y opposer à tout moment en cliquant sur le lien Personnaliser les cookies en bas de page. Pour en savoir plus, veuillez consulter notre ",
    bannerMessage2: "et notre charte d'",
    bannerMessage1Mobile:
      "Fubo, Molotov et leurs partenaires avec votre consentement peuvent utiliser des cookies ou équivalent pour stocker et/ou accéder à des informations sur votre terminal afin notamment de collecter des données",
    editSettings: "Personnaliser les cookies",
    accept: "Tout accepter",
    refuse: "Tout refuser",
    title: "Outil de gestion des cookies",
    subtitle:
      "Molotov dépose des cookies afin de garantir le bon fonctionnement de son site et personnaliser votre expérience ou les publicités qui vous sont proposées. Vous pouvez modifier vos préférences à tout moment.",
    section1: {
      title: "Cookies nécessaires",
      description:
        "Ces cookies sont essentiels au bon fonctionnement du site et des applications Molotov. Ils permettent notamment à Molotov d’identifier les utilisateurs lorsqu’ils sont connectés au site molotov.tv ou aux applications Molotov afin de leur fournir ses services, d’éviter les fraudes et de détecter des problèmes de navigation ou de sécurité.",
    },
    section2: {
      title: "Mesure d’audience",
      description: "Ces cookies permettent de connaître l’utilisation et les performances du site et d’en améliorer le fonctionnement.",
    },
    section3: {
      title: "Publicités",
      description:
        "Ces cookies permettent de vous proposer des publicités susceptibles de vous intéresser lors de votre navigation sur des sites tiers. Leur désactivation n’a aucun impact sur leur volume, uniquement sur leur pertinence.",
    },
    framelessMessage: "Les préférences de cookies ne peuvent être modifiées qu'en accédant à cette page",
    framelessMessageLink: "dans un navigateur.",
  },
  notifications: {
    success: "Vos préférences ont été mises à jour",
    handleNotifications: "Gérer mes abonnements",
  },
  promoCode: {
    link: "Je n'ai pas de code",
  },
  form: {
    commentErrorMessage: "Veuillez renseigner un commentaire (5 caractères minimum).",
  },
  productDesktopHeader: {
    step1: {
      title: "ETAPE 1 SUR 2",
      subtitle: "Choix du moyen de paiement",
    },
    step2: {
      title: "ETAPE 2 SUR 2",
      subtitle: "Votre abonnement est activé !",
    },
  },
  parentalControlPage: {
    options: [
      {
        name: "Enfants",
        label: {
          hide:
            "Les contenus <b>Adultes -18</b>, <b>Jeunes Adultes -16</b>, <b>Adolescents -12</b> et <b>Enfants -10</b> seront masqués de l'application.",
          pin:
            "Les contenus <b>Adultes -18</b>, <b>Jeunes adultes -16</b>, <b>Adolescents -12</b> et <b>Enfants -10</b> seront protégés par un code PIN.",
        },
        level: 1,
        icon: "childCare",
        disabled: {
          pin: false,
          hide: false,
        },
      },
      {
        name: "-10",
        label: {
          hide: "Les contenus <b>Adultes -18</b>, <b>Jeunes adultes -16</b> et <b>Adolescents -12</b> seront masqués de l’application.",
          pin: "Les contenus <b>Adultes -18</b>, <b>Jeunes adultes -16</b> et <b>Adolescents -12</b> seront protégés par un code PIN.",
        },
        level: 2,
        icon: "csa10CP",
        disabled: {
          pin: false,
          hide: false,
        },
      },
      {
        name: "-12",
        label: {
          hide: "Les contenus <b>Adultes -18</b> et <b>Jeunes adultes -16</b> seront masqués de l’application.",
          pin: "Les contenus <b>Adultes -18</b> et <b>Jeunes adultes -16</b> seront protégés par un code PIN.",
        },
        level: 3,
        icon: "csa12CP",
        disabled: {
          pin: false,
          hide: false,
        },
      },
      {
        name: "-16",
        label: {
          hide: "Les contenus <b>Adultes -18</b> sont masqués  de l’application.",
          pin: "Les contenus <b>Adultes -18</b> seront affichés mais protégés par un code PIN.",
        },
        level: 4,
        icon: "csa16CP",
        disabled: {
          pin: false,
          hide: false,
        },
      },
      {
        name: "-18",
        label: {
          hide: "",
          pin: "Les contenus <b>Adultes -18</b> seront affichés mais protégés par un code PIN.",
        },
        level: 5,
        icon: "csa18CP",
        disabled: {
          pin: false,
          hide: true,
        },
      },
    ],
    securityTypes: [
      {
        name: "hide",
        iconType: "invisibleLevel",
        title: "Par Masquage",
        description: "Faire disparaître les contenus sensibles de l’application.",
      },
      {
        name: "pin",
        iconType: "lock",
        title: "Par Code Pin",
        description: "Demander un code PIN pour regarder les contenus sensibles.",
      },
    ],
    toggleOptions: [
      {
        key: "blur_adult_covers",
        label: "Flouter les vignettes des contenus érotiques et pornographiques",
      },
      {
        key: "hide_adult_contents",
        label: "Masquer les contenus -18 ans",
      },
    ],
  },
  newNotifications: {
    submitSettings: "Contrôle parental activé.",
    pinCodeCreated: "Code PIN créé avec succès.",
    pinCodeEdited: "Code PIN modifié avec succès.",
  },
}
