import {
  GET_CHANNEL_PENDING,
  GET_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILED,
  GET_CHANNEL_REPLAY_PENDING,
  GET_CHANNEL_REPLAY_SUCCESS,
  GET_CHANNEL_REPLAY_FAILED,
  GET_CHANNEL_REPLAYS_PENDING,
  GET_CHANNEL_REPLAYS_SUCCESS,
  GET_CHANNEL_REPLAYS_FAILED,
  GET_FREE_CHANNELS_PENDING,
  GET_FREE_CHANNELS_SUCCESS,
  GET_FREE_CHANNELS_FAILED,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {
  direct: {},
  replay: {},
  replays: {},
  freeChannels: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CHANNEL_PENDING:
      return {
        ...state,
        direct: stateHelper.pending(),
      }

    case GET_CHANNEL_SUCCESS:
      return {
        ...state,
        direct: action.payload,
      }

    case GET_CHANNEL_FAILED:
      return {
        ...state,
        direct: { error: action.payload },
      }

    case GET_CHANNEL_REPLAY_PENDING:
      return {
        ...state,
        replay: stateHelper.pending(),
      }

    case GET_CHANNEL_REPLAY_SUCCESS:
      return {
        ...state,
        replay: action.payload,
      }

    case GET_CHANNEL_REPLAY_FAILED:
      return {
        ...state,
        replay: { error: action.payload },
      }

    case GET_CHANNEL_REPLAYS_PENDING:
      return {
        ...state,
        replays: stateHelper.pending(),
      }

    case GET_CHANNEL_REPLAYS_SUCCESS:
      return {
        ...state,
        replays: action.payload,
      }

    case GET_CHANNEL_REPLAYS_FAILED:
      return {
        ...state,
        replays: { error: action.payload },
      }

    case GET_FREE_CHANNELS_PENDING:
      return {
        ...state,
        freeChannels: stateHelper.pending(),
      }

    case GET_FREE_CHANNELS_SUCCESS:
      return {
        ...state,
        freeChannels: action.payload,
      }

    case GET_FREE_CHANNELS_FAILED:
      return {
        ...state,
        freeChannels: { error: action.payload },
      }

    default:
      return state
  }
}
