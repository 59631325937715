export default {
  // Authenticated pages
  account: "/account",
  devices: "/account/devices",
  offer: "/account/group-offers/:equivalenceCode",
  offers: "/account/products",
  parentalControl: "/account/parental-control",
  payment: "/account/payment",
  profile: "/account/profile",
  storage: "/account/storage",
  tv: "/account/tv",
  unsubscribeStep1: "/account/unsubscribe/:subscriptionId",
  unsubscribeStep2: "/account/unsubscribe/step2/:subscriptionId",
  notifications: "/account/notifications",

  promoCode: "/promo",
  newFunnel: "subscribe/COPT",

  // Public pages
  default: "/",
  about: "/about",
  category: "/:locale/cat/:categoryId/:categorySlug",
  categoryPage: "/:locale/cat/:categoryId/:categorySlug/page/:page",
  // https://www.molotov.tv/fr_fr/cat/1/movies/423/page

  changePassword: "/change-password/:token",
  changeEmail: "/change-email/:token",
  channel: "/:locale/c/:channelId/:channelSlug",
  channelReplay: "/:locale/r/:channelId/:channelSlug-replay",
  cookies: "/legal/cookies",
  cookiesSettings: "/legal/cookies/preferences",
  deeplink: "/deeplink",
  discoverProduct: "/account/products#offer_MOLOTOV_EXTRA",
  download: "/download",
  error: "/error",
  genre: "/:locale/cat/:categoryId/:categorySlug/:genreId/:genreSlug",
  genrePage: "/:locale/cat/:categoryId/:categorySlug/:genreId/:genreSlug/page/:page",

  giftcongrats: "/gift/congrats",
  gtu: "/legal/terms",
  hardware: "/devices",
  legal: "/legal",
  linktv: "/link",
  mentions: "/legal/notice",
  oauth: "/oauth/dialog",

  privacy: "/legal/privacy",
  products: "/offers",
  program: "/:locale/p/:programId-:channelId/:programSlug?",
  publicOffer: "/group-offers/:equivalenceCode",
  resetPassword: "/reset-password",
  resetEmail: "/reset-email",
  seoHome: "/:locale/programme-tv",
  tvReplay: "/regarder-tv-replay",
  movies: "/:locale/cat/1/movies",
  series: "/:locale/cat/2/tvshows",
  seoHomeSection: "/:locale/programme-tv/:section",
  signup: "/account/profile?signup=true",
  signupInformations: "/account/profile?signupInformations=true",
  subscribe: "/subscribe/:equivalenceCode",
  subscribePage: "/subscribe/:equivalenceCode?page=offer",
  landingStrapi: "/s/:url",
  tvPublic: "/tv",
  unsubscribeEmail: "/notificationspreferences/:userId/:hashedEmail/:emailType",

  // device pages
  deviceAmazonAlexa: "/tv-amazon-echo-show",
  deviceAndroid: "/tv-android",
  deviceAppleTv: "/tv-appletv",
  deviceChromecast: "/tv-chromecast",
  deviceDesktop: "/tv-ordinateur",
  deviceGoogleAssistant: "/tv-google-nest-hub",
  deviceHuawei: "/tv-huawei-appgallery",
  deviceIphone: "/tv-iphone-ipad",
  deviceLg: "/tv-smarttv-lg",
  devicePanasonic: "/tv-panasonic",
  deviceSamsung: "/tv-smarttv-samsung",
  deviceXbox: "/tv-xbox",
  deviceXiaomi: "/tv-mitvstick",
  deviceHisense: "/tv-hisense",
  tvDirect: "/regarder-tv-direct",
  tvFreeChannels: "/regarder-tv-gratuite",
  tvStreaming: "/regarder-tv-streaming",
  inBrowserUrl: {
    dev: "https://app-dev.molotov.tv",
    staging: "https://app-staging.molotov.tv",
    prod: "https://app.molotov.tv",
  },
}
