import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"
import { push } from "connected-react-router"

import Button from "components/Button"
import Loader from "components/Loader"
import Wave from "components/Wave"
import ProductInterstitial from "./ProductInterstitial"
import ProductPanel from "./ProductPanel"
import ProductMain from "./ProductMain"
import ProductPayment from "./ProductPayment"
import ProductPassword from "./ProductPassword"
import ProductConfirmation from "./ProductConfirmation"
import ProductWarning from "./ProductWarning"

import selectAuthentication from "selectors/authentication"
import selectProductBookmarkConstraints from "selectors/productBookmarkConstraints"
import { selectCheckoutProduct } from "selectors/products"

import { checkout as checkoutPayment } from "actions/products"
import { post as postSubscription, remove as removeSubscription, restore as restoreSubscription } from "actions/subscriptions"
import { getBookmarkConstraintsForProduct } from "actions/productBookmarkConstraints"
import { getOffers } from "actions/offers"

import { getAssetURL } from "helpers/static"
import formatterHelper from "helpers/formatter"
import deviceForwarder from "helpers/deviceForwarder"
import routeHelper from "helpers/route"
import UrlHelper from "helpers/url"
import apiAction from "helpers/apiAction"

import TrackingSegment from "managers/segmentManager"

import consts from "consts"
import routes from "consts/routes"
import i18n from "consts/i18n"
import styles from "./index.css"

const STEPS = {
  PRODUCT: "PRODUCT",
  ADD_PAYMENT: "ADD_PAYMENT",
  PASSWORD: "PASSWORD",
  RESUBSCRIBE_PASSWORD: "RESUBSCRIBE_PASSWORD",
  CONFIRMATION: "CONFIRMATION",
  RESILIATION_WARNING: "RESILIATION_WARNING",
  RESILIATION_PASSWORD: "RESILIATION_PASSWORD",
  RESILIATION_CONFIRMATION: "RESILIATION_CONFIRMATION",
  OPTINS: "OPTINS",
}

const SUBSCRIBE_STEPS = [STEPS.OPTINS, STEPS.ADD_PAYMENT, STEPS.PASSWORD, STEPS.CONFIRMATION]

const RESUBSCRIBE_STEPS = [STEPS.ADD_PAYMENT, STEPS.RESUBSCRIBE_PASSWORD, STEPS.CONFIRMATION]

const UNSUBSCRIBE_STEPS = [STEPS.RESILIATION_WARNING, STEPS.RESILIATION_PASSWORD, STEPS.RESILIATION_CONFIRMATION]

const chevronUp = getAssetURL("image.pagePayment.chevronUp")
const chevronDown = getAssetURL("image.pagePayment.chevronDown")

@connect((state, ownProps) => {
  return {
    appSettings: state.appSettings,
    isAuthenticated: selectAuthentication(state).isAuthenticated,
    paymentStep: state.mtvPay.step,
    productBookmarkConstraints: selectProductBookmarkConstraints(ownProps.product.id)(state),
    productCheckout: selectCheckoutProduct(state),
    session: state.session,
    periodicity: state.mtvPay.periodicity,
  }
})
export default class Product extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    customStyle: PropTypes.string,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    forceMobileDisplay: PropTypes.bool,
    forcePayment: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isFromTravelPage: PropTypes.bool,
    location: PropTypes.object,
    product: PropTypes.object,
    productBookmarkConstraints: PropTypes.bool,
    productCheckout: PropTypes.string,
    productIframeMode: PropTypes.bool,
    paymentTracker: PropTypes.func,
    paymentStep: PropTypes.object,
    session: PropTypes.object.isRequired,
    triggerSubscribe: PropTypes.bool,
    fromOptionPage: PropTypes.bool,
    periodicity: PropTypes.object,
  }

  static defaultProps = {
    customStyle: "",
    disabled: false,
    forceMobileDisplay: false,
    forcePayment: false,
    hasExtraChannels: true,
    isAuthenticated: false,
    isFromTravelPage: false,
    productIframeMode: false,
    paymentTracker: consts.NoOp,
    triggerSubscribe: false,
  }

  state = {
    currentSteps: [],
    stepIndex: -1,
    cgvVisible: false,
    cgv: {},
    showBeforeUnsub: false,
  }

  resetStep = () => {
    this.setState({ stepIndex: -1 })
  }

  nextStep = index => {
    const {
      appSettings: { platform },
      dispatch,
      isAuthenticated,
      product: { code, equivalence_code, id, isSubscribeWithApple, optins },
    } = this.props

    const { currentSteps, stepIndex } = this.state

    // console.log("nextStep called, current step is ", currentSteps[stepIndex]) // keep for debugging purpose

    if (!isAuthenticated) {
      dispatch(push(`${routes.offers}?subscribe=1#offer_${equivalence_code}`))
    } else {
      if (index) {
        return this.setState({ stepIndex: index })
      }
      // By default, move to the next step. Adjustements will be done below if needed (for optins,
      // post payment case, password form when cards data are already available etc.).
      let nextStepIndex = stepIndex + 1

      // if the next step is OPTINS and the product has no opt-ins, move to the next step (STEPS.ADD_PAYMENT)
      if (currentSteps[nextStepIndex] === STEPS.OPTINS && !optins) {
        nextStepIndex++
      }

      // redirect to app store for apple devices when we get to the payment step or password step and the sub was done with itunes
      if (
        platform === consts.platform.apple &&
        (currentSteps === SUBSCRIBE_STEPS || (currentSteps === RESUBSCRIBE_STEPS && isSubscribeWithApple)) &&
        (currentSteps[nextStepIndex] === STEPS.ADD_PAYMENT || currentSteps[nextStepIndex] === STEPS.PASSWORD)
      ) {
        window.location.href = `${consts.inappLink}${code}`
        return
      }

      if (currentSteps[nextStepIndex] === STEPS.ADD_PAYMENT) {
        dispatch(checkoutPayment(id))
      }

      this.setState({ stepIndex: nextStepIndex }, () => {
        if (this.state.currentSteps[this.state.stepIndex] === STEPS.CONFIRMATION) {
          this.callPostSubscriptionActions()
        }
      })
    }
  }

  showCGV = () => {
    this.setState({ cgvVisible: !this.state.cgvVisible })
  }

  callPostSubscriptionActions = () => {
    this.nextStep(this.state.currentSteps.length - 1)
    const { post_subscription_action } = this.props.product

    if (window.Android && typeof window.Android.subscribed === "function") {
      window.Android.subscribed()
    }

    if (post_subscription_action) {
      // send actions to android
      deviceForwarder.forward(post_subscription_action, "postSubscriptionAction")
    }

    this.props.dispatch(
      getOffers(this.props.appSettings.platform === consts.platform.apple ? consts.platformPayment.apple : consts.platformPayment.molotovpay)
    )
  }

  isDesktopMode = () => {
    return routeHelper.isDesktopMode(window.location.href)
  }

  triggerSubscribe = () => {
    const { isSubscribe, isSubscribeWithApple, subscriptionEndAt } = this.props.product

    if (isSubscribeWithApple) {
      return
    }

    if (!isSubscribe) {
      this.subscribeClick()
    } else if (!subscriptionEndAt) {
      this.unsubscribeClick()
    } else if (parseInt(subscriptionEndAt, 10) > 0) {
      this.reSubscribeClick()
    }

    this.triggerSubscribeApplied = true
  }

  subscribeClick = () => {
    if (!this.props.location || this.props.location === "/account/products") {
      return this.props.dispatch(push(`/subscribe/${this.props.product.equivalence_code}?page=offer`))
    }
    this.setState({ currentSteps: SUBSCRIBE_STEPS }, this.nextStep)
  }

  reSubscribeClick = () => {
    if (!this.props.location || this.props.location === "/account/products") {
      return this.props.dispatch(push(`/subscribe/${this.props.product.equivalence_code}?page=offer`))
    }
    this.setState({ currentSteps: RESUBSCRIBE_STEPS }, this.nextStep)
  }

  unsubscribeClick = () => {
    const isTBCC = this.props.product?.discount && this.props.product?.discount?.ratio === 1
    if (isTBCC) {
      this.setState({ showBeforeUnsub: true })
      TrackingSegment.trackEvent("Viewed modal Screen", {
        modal_type: "anti_churn",
        equivalence_code: this.props?.product?.equivalence_code,
      })
    } else {
      this.unsubscribeClickConfirm()
    }
  }

  unsubscribeClickConfirm = () => {
    // if eligible to winback
    if (get(this.props.session, "data.account.ab_testing.eligible_winback") === "true") {
      const subscriptionId = get(this.props.product, "subscriptionId")
      this.props.dispatch(push(formatterHelper.basic(consts.routes.unsubscribeStep1, { subscriptionId })))
      TrackingSegment.trackEvent("offer_button_clicked", { ...this.props.product, current_page: "account" })
    } else {
      this.setState({ currentSteps: UNSUBSCRIBE_STEPS }, this.nextStep)
    }
  }

  subscribe = () => {
    const { product } = this.props

    if (this.state.currentSteps === RESUBSCRIBE_STEPS) {
      this.props.dispatch(restoreSubscription(product.subscriptionId, this.nextStep))
    } else {
      this.props.dispatch(postSubscription(product.id, product.rate_plan_id, this.nextStep))
    }
  }

  unsubscribe = () => {
    this.props.dispatch(removeSubscription(this.props.product.subscriptionId, this.nextStep))
  }

  getUnsubBookmarksConstraints() {
    if (this.props.isAuthenticated && get(this.props, "terms.unsub_removes_bookmarks") && this.props.productBookmarkConstraints === null) {
      this.props.dispatch(getBookmarkConstraintsForProduct(this.props.product.id))
    }
  }

  getProductOptinsComponent(footer) {
    const {
      product: { id, optins },
    } = this.props

    let component = null

    switch (optins.type) {
      case "interstitial":
        component = (
          <ProductInterstitial
            customStyle={classNames(styles.main, styles.mainProductInterstitial)}
            onSubmit={this.subscribeClick}
            optins={optins}
            id={`${id}`}
            footer={footer}
          />
        )
        break

      default:
        console.error(`WARNING - Unsupported optins type ${optins.type}.`) // eslint-disable-line no-console
        break
    }

    return component
  }

  static getDerivedStateFromProps(props, state) {
    const { stepIndex } = state

    if (
      props.productCheckout &&
      props.productCheckout !== props.product.id &&
      state.currentSteps[stepIndex] === STEPS.ADD_PAYMENT &&
      !props.product.conversionDone
    ) {
      let nextStepIndex = stepIndex - 1

      if (state.currentSteps[nextStepIndex] === STEPS.OPTINS && !props.product.optins) {
        nextStepIndex--
      }

      return { stepIndex: nextStepIndex }
    }

    return null
  }

  componentDidMount() {
    // unauthorized user was redirected from the program CTA
    const storedOffer = JSON.parse(sessionStorage.getItem(consts.sessionStorageKey.programOfferToSubscribe))

    // && he already has subscription for a paid product
    if (
      storedOffer?.url &&
      this.props.product.isSubscribe &&
      window.location.pathname !== "/account/products" &&
      storedOffer?.programOffer?.product_equivalence_code === this.props.product.equivalence_code
    ) {
      sessionStorage.removeItem(consts.sessionStorageKey.programOfferToSubscribe)

      storedOffer.programOffer.isMobile ? (window.location = storedOffer.url) : UrlHelper.openInBrowser(storedOffer.url, true)
    }

    // && the product is from a free tier
    if (storedOffer?.url && storedOffer?.programOffer?.freeTier && window.location.pathname === "/account/products") {
      sessionStorage.removeItem(consts.sessionStorageKey.programOfferToSubscribe)

      storedOffer.programOffer.isMobile ? (window.location = storedOffer.url) : UrlHelper.openInBrowser(storedOffer.url, true)
    }

    if (this.props.triggerSubscribe) {
      this.triggerSubscribe()
    }
    // need to save this because cgv are removed from product payload on confirmation step
    this.setState({ cgv: this.props.product.reinsurance.footer })
    this.getUnsubBookmarksConstraints()
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerSubscribe && this.props.triggerSubscribe !== prevProps.triggerSubscribe && !this.triggerSubscribeApplied) {
      this.triggerSubscribe()
    }

    if (!prevProps.periodicity.offers && this.props.periodicity.offers) {
      const action = this.props.periodicity.offers[0].buy_button.actions.buy

      this.props.dispatch(apiAction.executeAction(action))
    }

    this.getUnsubBookmarksConstraints()
  }

  render() {
    const {
      appSettings: { platform, frameless, frameType },
      customStyle,
      disabled,
      forceMobileDisplay,
      isFromTravelPage,
      paymentStep,
      product,
      product: {
        equivalence_code,
        feature_list,
        id,
        isSubscribe,
        name,
        subscriptionEndAt,
        type,
        will_lose_storage_on_cancel,
        wording,
        // to be removed
        cancellation_success_message,
      },
      productBookmarkConstraints,
      productIframeMode,
      fromOptionPage,
    } = this.props

    const { stepIndex, cgv, cgvVisible, showBeforeUnsub } = this.state

    const step = this.state.currentSteps[stepIndex] || STEPS.PRODUCT
    let headerTitle = (wording && wording.title) || null
    let headerSubtitle = (wording && wording.subtitle) || null
    let periodicity = (wording && wording.periodicity) || null

    if (headerTitle && typeof headerTitle === "object") {
      headerTitle = formatterHelper.styled(headerTitle.format, headerTitle.parts)
    }
    if (headerSubtitle && typeof headerSubtitle === "object") {
      headerSubtitle = formatterHelper.styled(headerSubtitle.format, headerSubtitle.parts)
    }
    if (periodicity && typeof periodicity === "object") {
      periodicity = formatterHelper.styled(periodicity.format, periodicity.parts)
    }

    const wave = <Wave customStyle={styles.wave} color={product.color} />
    const footer = get(product, "reinsurance.footer") ? (
      <div className={styles.footer}>{formatterHelper.styledFormatter(product.reinsurance.footer, "footer")}</div>
    ) : null

    return (
      <>
        {showBeforeUnsub && (
          <div className={styles.unsubModal}>
            <div className={styles.unsubContainer}>
              <div className={styles.unsubTitle}>Attention !</div>
              <p className={styles.unsubText}>
                Vous n’aurez plus accès aux contenus et fonctionnalités de cette option <b>immédiatement</b>.
              </p>
              <div className={styles.unsubButtons}>
                <Button
                  color={Button.colors.greyFull}
                  customStyle={classNames(styles.unsubButton, styles.unsubButtonTop)}
                  value={"J’ai compris"}
                  onClick={this.unsubscribeClickConfirm}
                />
                <Link to={"/"} className={styles.unsubButton}>
                  <Button color={Button.colors.yellowFull} customStyle={styles.unsubButton} value={"Conserver mes avantages"} />
                </Link>
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(styles.root, customStyle, {
            [styles.rootLegacy]: step !== STEPS.PRODUCT,
            [styles.active]: isSubscribe && step !== STEPS.CONFIRMATION,
            [styles.applePlatform]: platform === consts.platform.apple,
            [styles.disabled]: disabled,
            [styles.mobileDisplay]: forceMobileDisplay,
            [styles.mobilePlatform]: platform === consts.platform.apple || platform === consts.platform.android,
            [styles.productIframeMode]: productIframeMode,
          })}
        >
          <div id={`offer_${equivalence_code}`} className={styles.anchor} />
          {// We hide the checkmark icon intentionally on "frameless + productIframeMode" mode because it overlaps with the close icon in web-app-desktop
          // webview (when in productPageIframe). And also because the checkmark icon would be cropped since the webview size fits exactly the
          // productPageIframe size.
          isSubscribe && step !== STEPS.CONFIRMATION && !(productIframeMode && frameless) && <div className={styles.checkmark} />}
          {step === STEPS.PRODUCT && (
            <ProductPanel
              customStyle={styles.panel}
              forceMobileDisplay={forceMobileDisplay}
              footer={footer}
              frameType={frameType}
              product={product}
              wave={wave}
            />
          )}

          <div className={styles.productDynamicPanel}>
            {step === STEPS.OPTINS && this.getProductOptinsComponent(footer)}

            {!this.props.forcePayment && step === STEPS.PRODUCT && (
              <ProductMain
                customStyle={styles.main}
                disabled={disabled}
                footer={footer}
                frameType={frameType}
                onReSubscribe={this.reSubscribeClick}
                onSubscribe={this.subscribeClick}
                onUnsubscribe={this.unsubscribeClick}
                platform={platform}
                product={product}
              />
            )}
            {this.props.forcePayment || step === STEPS.ADD_PAYMENT ? (
              <ProductPayment
                callPostSubscriptionActions={this.callPostSubscriptionActions}
                customStyle={styles.main}
                footer={footer}
                isFramelessMode={frameless}
                onCancel={this.resetStep}
                onSuccess={this.subscribe}
                paymentTracker={this.props.paymentTracker}
                product={product}
                equivalenceCode={equivalence_code}
                fromOptionPage={fromOptionPage}
              />
            ) : null}
            {(step === STEPS.PASSWORD || step === STEPS.RESUBSCRIBE_PASSWORD || step === STEPS.RESILIATION_PASSWORD) && (
              <div className={styles.main}>
                <ProductPassword
                  id={`${id}`}
                  isReSubscribing={step === STEPS.RESUBSCRIBE_PASSWORD}
                  isSubscribe={isSubscribe}
                  footer={footer}
                  onCancel={this.resetStep}
                  onSuccess={isSubscribe && step !== STEPS.RESUBSCRIBE_PASSWORD ? this.unsubscribe : this.subscribe}
                />

                {(step === STEPS.PASSWORD || step === STEPS.RESUBSCRIBE_PASSWORD) && <p className={styles.cgu}>{i18n.product.cguAware}</p>}
              </div>
            )}
            {(step === STEPS.CONFIRMATION || step === STEPS.RESILIATION_CONFIRMATION) && !this.isDesktopMode() && (
              <ProductConfirmation
                customStyle={styles.main}
                discount={product.discount}
                footer={footer}
                isFromTravelPage={isFromTravelPage}
                isResiliation={step === STEPS.RESILIATION_CONFIRMATION}
                onClose={this.resetStep}
                resiliationMessages={cancellation_success_message}
                subscriptionEndAt={subscriptionEndAt}
                subscriptionMessages={paymentStep}
                nextTemplate
                productName={product.name}
                productCode={product.equivalence_code}
                productOnTrial={product?.discounted_price === 0}
                warningMessage={product.warning_message}
              />
            )}
            {(step === STEPS.CONFIRMATION || step === STEPS.RESILIATION_CONFIRMATION) && this.isDesktopMode() && <Loader centerInParent={true} />}
            {step === STEPS.RESILIATION_WARNING && (
              <ProductWarning
                customStyle={styles.main}
                footer={footer}
                hasBookmarkConstraints={productBookmarkConstraints}
                willLoseStorageOnUnsub={will_lose_storage_on_cancel}
                hasExtraChannels={feature_list.hasExtraChannels}
                onCancel={this.resetStep}
                onValidate={this.nextStep}
                productTitle={name}
                type={type}
              />
            )}
          </div>
        </div>

        {step !== STEPS.PRODUCT && footer && cgv.parts.length > 1 && (
          <div className={styles.cgvFooter} onClick={this.showCGV}>
            <div className={styles.cgvTitle}>
              <span>Conditions générales de vente </span>
              <span>
                {cgvVisible ? <img loading="lazy" src={chevronUp} alt="chevron up" /> : <img loading="lazy" src={chevronDown} alt="chevron down" />}
              </span>
            </div>
            {cgvVisible ? <div>{formatterHelper.styledFormatter(cgv, "footer")}</div> : null}
          </div>
        )}
      </>
    )
  }
}
