// FAKE : example
export const ADD_EXAMPLE = "ADD_EXAMPLE"
export const RESET_EXAMPLES = "RESET_EXAMPLES"

// FAKE : alert
export const NEW_ALERT = "NEW_ALERT"

// session
export const CHOOSE_CURRENT_PROFILE = "CHOOSE_CURRENT_PROFILE"
export const SET_SESSION = "SET_SESSION"
export const LOGIN_PENDING = "LOGIN_PENDING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT = "LOGOUT"
export const ACCESSTOKEN_REFRESH_PENDING = "ACCESSTOKEN_REFRESH_PENDING"
export const AUTOLOGIN = "AUTOLOGIN"
export const AUTOLOGIN_ERROR = "AUTOLOGIN_ERROR"
export const CHECK_LOGIN = "CHECK_LOGIN"
export const PENDING_CLIENT_SESSION = "PENDING_CLIENT_SESSION"
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR"
export const OAUTH_AUTHORIZE_PENDING = "OAUTH_AUTHORIZE_PENDING"
export const OAUTH_AUTHORIZE_SUCCESS = "OAUTH_AUTHORIZE_SUCCESS"
export const OAUTH_AUTHORIZE_FAILURE = "OAUTH_AUTHORIZE_FAILURE"
export const OAUTH_AUTHORIZE_REQUESTED = "OAUTH_AUTHORIZE_REQUESTED"
export const SOCIAL_LOGIN_ERROR = "SOCIAL_LOGIN_ERROR"
export const SET_HAS_PASSWORD = "SET_HAS_PASSWORD"

// Others
export const CHECK_REQUEST_QUEUE = "CHECK_REQUEST_QUEUE"
export const SET_SIGNUP_STATE = "SET_SIGNUP_STATE"
export const SET_APP_SETTINGS = "SET_APP_SETTINGS"

// user
export const GET_ACCOUNT_PENDING = "GET_ACCOUNT_PENDING"
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS"
export const GET_ACCOUNT_FAILED = "GET_ACCOUNT_FAILED"

export const GET_ME_PENDING = "GET_ME_PENDING"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const GET_ME_FAILED = "GET_ME_FAILED"

export const UPDATE_ACCOUNT_PENDING = "UPDATE_ACCOUNT_PENDING"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAILED = "UPDATE_ACCOUNT_FAILED"

export const POST_ACCOUNT_PENDING = "POST_ACCOUNT_PENDING"
export const POST_ACCOUNT_SUCCESS = "POST_ACCOUNT_SUCCESS"
export const POST_ACCOUNT_FAILED = "POST_ACCOUNT_FAILED"

export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED"

// Parental Control
export const SUBMIT_SETTINGS_PENDING = "SUBMIT_SETTINGS_PENDING"
export const SUBMIT_SETTINGS_SUCCESS = "SUBMIT_SETTINGS_SUCCESS"
export const SUBMIT_SETTINGS_FAILED = "SUBMIT_SETTINGS_FAILED"
export const SUBMIT_CODE_PIN_SUCCESS = "SUBMIT_CODE_PIN_SUCCESS"
export const SUBMIT_CODE_PIN_FAILED = "SUBMIT_CODE_PIN_FAILED"
export const RESET_PARENTAL_CONTROL = "RESET_PARENTAL_CONTROL"
export const SUBMIT_PASSWORD_CHECK_PENDING = "SUBMIT_PASSWORD_CHECK_PENDING"
export const SUBMIT_PASSWORD_CHECK_SUCCESS = "SUBMIT_PASSWORD_CHECK_SUCCESS"
export const SUBMIT_PASSWORD_CHECK_FAILED = "SUBMIT_PASSWORD_CHECK_FAILED"

// Notifications
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const ACTION_FEEDBACK = "ACTION_FEEDBACK"

export const UPDATE_CURRENTPROFILE_PENDING = "UPDATE_CURRENTPROFILE_PENDING"
export const UPDATE_CURRENTPROFILE_SUCCESS = "UPDATE_CURRENTPROFILE_SUCCESS"
export const UPDATE_CURRENTPROFILE_ERROR = "UPDATE_CURRENTPROFILE_ERROR"

// email
export const UPDATE_EMAIL_PENDING = "UPDATE_EMAIL_PENDING"
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS"
export const UPDATE_EMAIL_FAILED = "UPDATE_EMAIL_FAILED"
export const RESET_EMAIL_PENDING = "RESET_EMAIL_PENDING"
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS"
export const RESET_EMAIL_FAILED = "RESET_EMAIL_FAILED"
export const RESET_EMAIL_FORM = "RESET_EMAIL_FORM"
export const RESET_EMAIL_ERROR = "RESET_EMAIL_ERROR"
export const CLEAR_EMAIL_FORM = "CLEAR_EMAIL_FORM"

// password
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED"
export const UPDATE_EMAIL_RESET = "UPDATE_EMAIL_RESET"
export const CHECK_PASSWORD_PENDING = "CHECK_PASSWORD_PENDING"
export const CHECK_PASSWORD_SUCCESS = "CHECK_PASSWORD_SUCCESS"
export const CHECK_PASSWORD_FAILED = "CHECK_PASSWORD_FAILED"
export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM"

// modal
export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

// flash
export const OPEN_FLASH = "OPEN_FLASH"
export const CLOSE_FLASH = "CLOSE_FLASH"

// references
export const GET_REFERENCES_PENDING = "GET_REFERENCES_PENDING"
export const GET_REFERENCES_SUCCESS = "GET_REFERENCES_SUCCESS"
export const GET_REFERENCES_FAILED = "GET_REFERENCES_FAILED"
export const GET_APP_REFERENCES_PENDING = "GET_APP_REFERENCES_PENDING"
export const GET_APP_REFERENCES_SUCCESS = "GET_APP_REFERENCES_SUCCESS"
export const GET_APP_REFERENCES_FAILED = "GET_APP_REFERENCES_FAILED"

// metrics
export const GET_RECORD_METRICS_PENDING = "GET_RECORD_METRICS_PENDING"
export const GET_RECORD_METRICS_SUCCESS = "GET_RECORD_METRICS_SUCCESS"
export const GET_RECORD_METRICS_FAILED = "GET_RECORD_METRICS_FAILED"

export const GET_SCHEDULED_METRICS_PENDING = "GET_SCHEDULED_METRICS_PENDING"
export const GET_SCHEDULED_METRICS_SUCCESS = "GET_SCHEDULED_METRICS_SUCCESS"
export const GET_SCHEDULED_METRICS_FAILED = "GET_SCHEDULED_METRICS_FAILED"

// Reset password
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

// devices
export const GET_DEVICES_PENDING = "GET_DEVICES_PENDING"
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
export const GET_DEVICES_FAILED = "GET_DEVICES_FAILED"

export const SET_DEVICES = "SET_DEVICES"

export const REMOVE_DEVICES_PENDING = "REMOVE_DEVICES_PENDING"
export const REMOVE_DEVICES_DONE = "REMOVE_DEVICES_DONE"

// bookmarks
export const GET_BOOKMARKS_RECORD_PENDING = "GET_BOOKMARKS_RECORD_PENDING"
export const GET_BOOKMARKS_RECORD_SUCCESS = "GET_BOOKMARKS_RECORD_SUCCESS"
export const GET_BOOKMARKS_RECORD_FAILED = "GET_BOOKMARKS_RECORD_FAILED"

export const TOGGLE_BOOKMARK_RECORD = "TOGGLE_BOOKMARK_RECORD"

export const GET_BOOKMARKS_SCHEDULED_PENDING = "GET_BOOKMARKS_SCHEDULED_PENDING"
export const GET_BOOKMARKS_SCHEDULED_SUCCESS = "GET_BOOKMARKS_SCHEDULED_SUCCESS"
export const GET_BOOKMARKS_SCHEDULED_FAILED = "GET_BOOKMARKS_SCHEDULED_FAILED"

export const TOGGLE_BOOKMARK_SCHEDULED = "TOGGLE_BOOKMARK_SCHEDULED"
export const TOGGLE_BOOKMARKS = "TOGGLE_BOOKMARKS"

export const DELETE_BOOKMARKS_RECORD_PENDING = "DELETE_BOOKMARKS_RECORD_PENDING"
export const DELETE_BOOKMARKS_RECORD_SUCCESS = "DELETE_BOOKMARKS_RECORD_SUCCESS"
export const DELETE_BOOKMARKS_RECORD_FAILED = "DELETE_BOOKMARKS_RECORD_FAILED"

export const DELETE_BOOKMARKS_SCHEDULED_PENDING = "DELETE_BOOKMARKS_SCHEDULED_PENDING"
export const DELETE_BOOKMARKS_SCHEDULED_SUCCESS = "DELETE_BOOKMARKS_SCHEDULED_SUCCESS"
export const DELETE_BOOKMARKS_SCHEDULED_FAILED = "DELETE_BOOKMARKS_SCHEDULED_FAILED"

export const DELETE_ALL_BOOKMARKS_RECORD_PENDING = "DELETE_ALL_BOOKMARKS_RECORD_PENDING"
export const DELETE_ALL_BOOKMARKS_RECORD_SUCCESS = "DELETE_ALL_BOOKMARKS_RECORD_SUCCESS"
export const DELETE_ALL_BOOKMARKS_RECORD_FAILED = "DELETE_ALL_BOOKMARKS_RECORD_FAILED"

export const DELETE_ALL_BOOKMARKS_SCHEDULED_PENDING = "DELETE_ALL_BOOKMARKS_SCHEDULED_PENDING"
export const DELETE_ALL_BOOKMARKS_SCHEDULED_SUCCESS = "DELETE_ALL_BOOKMARKS_SCHEDULED_SUCCESS"
export const DELETE_ALL_BOOKMARKS_SCHEDULED_FAILED = "DELETE_ALL_BOOKMARKS_SCHEDULED_FAILED"

export const DELETE_FUTURE_BOOKMARKS_SCHEDULED_PENDING = "DELETE_FUTURE_BOOKMARKS_SCHEDULED_PENDING"
export const DELETE_FUTURE_BOOKMARKS_SCHEDULED_FAILED = "DELETE_FUTURE_BOOKMARKS_SCHEDULED_FAILED"

export const SET_BOOKMARKS_RECORD = "SET_BOOKMARKS_RECORD"
export const SET_BOOKMARKS_SCHEDULED = "SET_BOOKMARKS_SCHEDULED"

// product
export const GET_FREE_PRODUCT_PENDING = "GET_FREE_PRODUCT_PENDING"
export const GET_FREE_PRODUCT_SUCCESS = "GET_FREE_PRODUCT_SUCCESS"
export const GET_FREE_PRODUCT_FAILED = "GET_FREE_PRODUCT_FAILED"

export const GET_PRODUCT_CONSTRAINTS_PENDING = "GET_PRODUCT_CONSTRAINTS_PENDING"
export const GET_PRODUCT_CONSTRAINTS_SUCCESS = "GET_PRODUCT_CONSTRAINTS_SUCCESS"
export const GET_PRODUCT_CONSTRAINTS_FAILED = "GET_PRODUCT_CONSTRAINTS_FAILED"
export const UPDATE_PRODUCT_OPTINS = "UPDATE_PRODUCT_OPTINS"

export const GET_PRODUCT_LIST_PENDING = "GET_PRODUCT_LIST_PENDING"
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS"
export const GET_PRODUCT_LIST_FAILED = "GET_PRODUCT_LIST_FAILED"

export const SET_CURRENT_PRODUCT_CHECKOUT = "SET_CURRENT_PRODUCT_CHECKOUT"

// ab testing
export const GET_PAYPAL_AB_TESTING_PENDING = "GET_PAYPAL_AB_TESTING_PENDING"
export const GET_PAYPAL_AB_TESTING_SUCCESS = "GET_PAYPAL_AB_TESTING_SUCCESS"
export const GET_PAYPAL_AB_TESTING_FAILED = "GET_PAYPAL_AB_TESTING_FAILED"

// subscriptions
export const GET_SUBSCRIPTIONS_PENDING = "GET_SUBSCRIPTIONS_PENDING"
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAILED = "GET_SUBSCRIPTIONS_FAILED"

export const POST_SUBSCRIPTIONS_PENDING = "POST_SUBSCRIPTIONS_PENDING"
export const POST_SUBSCRIPTIONS_SUCCESS = "POST_SUBSCRIPTIONS_SUCCESS"
export const POST_SUBSCRIPTIONS_FAILED = "POST_SUBSCRIPTIONS_FAILED"

export const DELETE_SUBSCRIPTIONS_PENDING = "DELETE_SUBSCRIPTIONS_PENDING"
export const DELETE_SUBSCRIPTIONS_SUCCESS = "DELETE_SUBSCRIPTIONS_SUCCESS"
export const DELETE_SUBSCRIPTIONS_FAILED = "DELETE_SUBSCRIPTIONS_FAILED"

export const RESET_SUBSCRIPTIONS_ERROR = "RESET_SUBSCRIPTIONS_ERROR"

export const CLEAN_SUBSCRIPTIONS = "CLEAN_SUBSCRIPTIONS"

export const POST_CONVERSION = "POST_CONVERSION"

// Molotov Pay Payment method & Invoices

export const CARD_FORM_REQUESTED = "CARD_FORM_REQUESTED"
export const DELETE_MTVPAY_CARD_SUCCESS = "DELETE_MTVPAY_CARD_SUCCESS"
export const INFOS_SCREEN_REQUESTED = "INFOS_SCREEN_REQUESTED"
export const GET_MTVPAY_PAYMENT_METHOD_PENDING = "GET_MTVPAY_PAYMENT_METHOD_PENDING"
export const GET_MTVPAY_PAYMENT_METHOD_SUCCESS = "GET_MTVPAY_PAYMENT_METHOD_SUCCESS"
export const GET_MTVPAY_PAYMENT_METHOD_FAILED = "GET_MTVPAY_PAYMENT_METHOD_FAILED"
export const DELETE_MTVPAY_PAYMENT_METHOD_PENDING = "DELETE_MTVPAY_PAYMENT_METHOD_PENDING"
export const DELETE_MTVPAY_PAYMENT_METHOD_FAILED = "DELETE_MTVPAY_PAYMENT_METHOD_FAILED"
export const DELETE_MTVPAY_PAYMENT_METHOD_SUCCESS = "DELETE_MTVPAY_PAYMENT_METHOD_SUCCESS"
export const GET_MTVPAY_INVOICES_PENDING = "GET_MTVPAY_INVOICES_PENDING"
export const GET_MTVPAY_INVOICES_SUCCESS = "GET_MTVPAY_INVOICES_SUCCESS"
export const GET_MTVPAY_INVOICES_FAILED = "GET_MTVPAY_INVOICES_FAILED"
export const SAVE_MTVPAY_CARD_PENDING = "SAVE_MTVPAY_CARD_PENDING"
export const SAVE_MTVPAY_CARD_SUCCESS = "SAVE_MTVPAY_CARD_SUCCESS"
export const SAVE_MTVPAY_CARD_ERROR = "SAVE_MTVPAY_CARD_ERROR"

// Molotov Pay Payment Form

export const CLOSE_MTVPAY_FORM = "CLOSE_MTVPAY_FORM"
export const CLOSE_MTVPAY_INTERSTITIAL = "CLOSE_MTVPAY_INTERSTITIAL"
export const GET_MTVPAY_PASSWORD_FORM_PENDING = "GET_MTVPAY_PASSWORD_FORM_PENDING"
export const GET_MTVPAY_PASSWORD_FORM_SUCCESS = "GET_MTVPAY_PASSWORD_FORM_SUCCESS"
export const GET_MTVPAY_PASSWORD_FORM_ERROR = "GET_MTVPAY_PASSWORD_FORM_ERROR"
export const GET_MTVPAY_PAYMENT_FORM_PENDING = "GET_MTVPAY_PAYMENT_FORM_PENDING"
export const GET_MTVPAY_PAYMENT_FORM_SUCCESS = "GET_MTVPAY_PAYMENT_FORM_SUCCESS"
export const GET_MTVPAY_PAYMENT_FORM_ERROR = "GET_MTVPAY_PAYMENT_FORM_ERROR"
export const GOT_BUY_ACTION_ERROR = "GOT_BUY_ACTION_ERROR"
export const INVALID_CARD_INFORMATIONS = "INVALID_CARD_INFORMATIONS"
export const MTVPAY_PAYMENT_PENDING = "MTVPAY_PAYMENT_PENDING"
export const MTVPAY_PAYMENT_SUCCESS = "MTVPAY_PAYMENT_SUCCESS"
export const MTVPAY_PAYMENT_ERROR = "MTVPAY_PAYMENT_ERROR"
export const RESET_CARD_ERRORS = "RESET_CARD_ERRORS"
export const SET_MTVPAY_INTERSTITIAL = "SET_MTVPAY_INTERSTITIAL"
export const GET_MTV_PAY_STEP_PENDING = "GET_MTV_PAY_STEP_PENDING"
export const GET_MTV_PAY_STEP_SUCCESS = "GET_MTV_PAY_STEP_SUCCESS"
export const GET_MTV_PAY_STEP_FAILED = "GET_MTV_PAY_STEP_FAILED"
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA"
export const PRODUCT_PAYMENT_INSTANCIATED = "PRODUCT_PAYMENT_INSTANCIATED"
export const SET_MTVPAY_PASSWORD_ERROR = "SET_MTVPAY_PASSWORD_ERROR"
export const GET_OFFER_PERIODICITY_PENDING = "GET_OFFER_PERIODICITY_PENDING"
export const GET_OFFER_PERIODICITY_SUCCESS = "GET_OFFER_PERIODICITY_SUCCESS"
export const GET_OFFER_PERIODICITY_FAILED = "GET_OFFER_PERIODICITY_FAILED"
export const SELECTED_PERIODICITY = "SELECTED_PERIODICITY"
export const RECURLY_3DS_TOKEN = "RECURLY_3DS_TOKEN"

// Download
export const GET_DOWNLOAD_LINK_PENDING = "GET_DOWNLOAD_LINK_PENDING"
export const GET_DOWNLOAD_LINK_SUCCESS = "GET_DOWNLOAD_LINK_SUCCESS"
export const GET_DOWNLOAD_LINK_FAILED = "GET_DOWNLOAD_LINK_FAILED"

// Offers
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS"
export const GET_OFFERS_FAILED = "GET_OFFERS_FAILED"
export const GET_MULTIPLE_OFFERS = "GET_MULTIPLE_OFFERS"

// UI
export const ALLOW_CLIENT_RENDER = "ALLOW_CLIENT_RENDER"
export const APP_ERROR = "APP_ERROR"
export const CLEAR_BACKGROUND_IMAGE = "CLEAR_BACKGROUND_IMAGE"
export const CLOSE_SEO_SUB_MENU = "CLOSE_SEO_SUB_MENU"
export const HIDE_HEADER_MENU = "HIDE_HEADER_MENU"
export const HIDE_NAV = "HIDE_NAV"
export const HIDE_SEO_NAV = "HIDE_SEO_NAV"
export const SCROLL = "SCROLL"
export const SET_404_ERROR = "SET_404_ERROR"
export const SET_DOCUMENT_HEIGHT = "SET_DOCUMENT_HEIGHT"
export const SET_LOGO_OFFSET_TOP = "SET_LOGO_OFFSET_TOP"
export const SET_SEO_CTA_FOOTER_HEIGHT = "SET_SEO_CTA_FOOTER_HEIGHT"

export const SET_WINDOW_HEIGHT = "SET_WINDOW_HEIGHT"
export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH"
export const SHOW_SEO_NAV = "SHOW_SEO_NAV"
export const TOGGLE_HEADER_MENU = "TOGGLE_HEADER_MENU"
export const TOGGLE_SEO_MENU = "TOGGLE_SEO_MENU"
export const TOGGLE_MOBILE_NAV = "TOGGLE_MOBILE_NAV"

// Programs
export const GET_HOMEPAGE_PROGRAMS_PENDING = "GET_HOMEPAGE_PROGRAMS_PENDING"
export const GET_HOMEPAGE_PROGRAMS_SUCCESS = "GET_HOMEPAGE_PROGRAMS_SUCCESS"
export const GET_HOMEPAGE_PROGRAMS_FAILED = "GET_HOMEPAGE_PROGRAMS_FAILED"
export const GET_PROGRAMS_PENDING = "GET_PROGRAMS_PENDING"
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS"
export const GET_PROGRAMS_FAILED = "GET_PROGRAMS_FAILED"

// Program
export const GET_PROGRAM_PENDING = "GET_PROGRAM_PENDING"
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS"
export const GET_PROGRAM_FAILED = "GET_PROGRAM_FAILED"

// Episodes
export const GET_EPISODE_PENDING = "GET_EPISODE_PENDING"
export const GET_EPISODE_SUCCESS = "GET_EPISODE_SUCCESS"
export const GET_EPISODE_FAILED = "GET_EPISODE_FAILED"

// Navigator
export const SET_NAVIGATOR = "SET_NAVIGATOR"

// Categories
export const GET_CATEGORY_PENDING = "GET_CATEGORY_PENDING"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED"

// Genres
export const GET_GENRE_PENDING = "GET_GENRE_PENDING"
export const GET_GENRE_SUCCESS = "GET_GENRE_SUCCESS"
export const GET_GENRE_FAILED = "GET_GENRE_FAILED"

// Channel
export const GET_CHANNEL_PENDING = "GET_CHANNEL_PENDING"
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS"
export const GET_CHANNEL_FAILED = "GET_CHANNEL_FAILED"
export const GET_CHANNEL_REPLAY_PENDING = "GET_CHANNEL_REPLAY_PENDING"
export const GET_CHANNEL_REPLAY_SUCCESS = "GET_CHANNEL_REPLAY_SUCCESS"
export const GET_CHANNEL_REPLAY_FAILED = "GET_CHANNEL_REPLAY_FAILED"
export const GET_CHANNEL_REPLAYS_PENDING = "GET_CHANNEL_REPLAYS_PENDING"
export const GET_CHANNEL_REPLAYS_SUCCESS = "GET_CHANNEL_REPLAYS_SUCCESS"
export const GET_CHANNEL_REPLAYS_FAILED = "GET_CHANNEL_REPLAYS_FAILED"

export const GET_FREE_CHANNELS_PENDING = "GET_FREE_CHANNELS_PENDING"
export const GET_FREE_CHANNELS_SUCCESS = "GET_FREE_CHANNELS_SUCCESS"
export const GET_FREE_CHANNELS_FAILED = "GET_FREE_CHANNELS_FAILED"

// ServerError
export const SET_SERVER_ERROR = "SET_SERVER_ERROR"
export const LOG_ERROR = "LOG_ERROR"

// Person
export const GET_PERSON_PENDING = "GET_PERSON_PENDING"
export const GET_PERSON_SUCCESS = "GET_PERSON_SUCCESS"
export const GET_PERSON_FAILED = "GET_PERSON_FAILED"

// SEO
export const GET_SEO_CONFIG_PENDING = "GET_SEO_CONFIG_PENDING"
export const GET_SEO_CONFIG_SUCCESS = "GET_SEO_CONFIG_SUCCESS"
export const GET_SEO_CONFIG_FAILED = "GET_SEO_CONFIG_FAILED"

// SEO LANDING MENU
export const GET_SEO_LANDING_MENU_PENDING = "GET_SEO_LANDING_MENU_PENDING"
export const GET_SEO_LANDING_MENU_SUCCESS = "GET_SEO_LANDING_MENU_SUCCESS"
export const GET_SEO_LANDING_MENU_FAILED = "GET_SEO_LANDING_MENU_FAILED"

// Strapi Landing
export const GET_STRAPI_LANDING_CONFIG_PENDING = "GET_STRAPI_LANDING_CONFIG_PENDING"
export const GET_STRAPI_LANDING_CONFIG_SUCCESS = "GET_STRAPI_LANDING_CONFIG_SUCCESS"
export const GET_STRAPI_LANDING_CONFIG_FAILED = "GET_STRAPI_LANDING_CONFIG_FAILED"

// Link
export const LINK_VALIDATE_CODE_PENDING = "LINK_VALIDATE_CODE_PENDING"
export const LINK_VALIDATE_CODE_SUCCESS = "LINK_VALIDATE_CODE_SUCCESS"
export const LINK_VALIDATE_CODE_ERROR = "LINK_VALIDATE_CODE_ERROR"
export const LINK_ACTIVATE_CODE_PENDING = "LINK_ACTIVATE_CODE_PENDING"
export const LINK_ACTIVATE_CODE_SUCCESS = "LINK_ACTIVATE_CODE_SUCCESS"
export const LINK_ACTIVATE_CODE_ERROR = "LINK_ACTIVATE_CODE_ERROR"

// Cookie Banner / Modal
export const DISPLAY_COOKIE_BANNER = "DISPLAY_COOKIE_BANNER"
export const HIDE_COOKIE_SETTINGS = "HIDE_COOKIE_SETTINGS"
export const SET_PRIVACY_SETTINGS = "SET_PRIVACY_SETTINGS"
export const APPLY_PRIVACY_SETTINGS = "APPLY_PRIVACY_SETTINGS"
export const TOGGLE_DIDOMI_BANNER = "TOGGLE_DIDOMI_BANNER"

// Notifications
export const CHECK_NOTIFICATIONS_OPTIN = "CHECK_NOTIFICATIONS_OPTIN"
export const GET_NOTIFICATIONS_PENDING = "GET_NOTIFICATIONS_PENDING"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"
export const SAVE_NOTIFICATIONS_PENDING = "SAVE_NOTIFICATIONS_PENDING"
export const SAVE_NOTIFICATIONS_SUCCESS = "SAVE_NOTIFICATIONS_SUCCESS"
export const SAVE_NOTIFICATIONS_ERROR = "SAVE_NOTIFICATIONS_ERROR"
export const DELETE_EMAIL_SUBSCRIPTION_PENDING = "DELETE_EMAIL_SUBSCRIPTION_PENDING"
export const DELETE_EMAIL_SUBSCRIPTION_SUCCESS = "DELETE_EMAIL_SUBSCRIPTION_SUCCESS"
export const DELETE_EMAIL_SUBSCRIPTION_ERROR = "DELETE_EMAIL_SUBSCRIPTION_ERROR"

// Molotov Gift
export const CLEAR_GIFT_OPTIONS = "GET_GIFT_CLAIM_PENDING"
export const GET_GIFT_CONGRATS_PENDING = "GET_GIFT_CONGRATS_PENDING"
export const GET_GIFT_CONGRATS_SUCCESS = "GET_GIFT_CONGRATS_SUCCESS"
export const GET_GIFT_CONGRATS_FAILED = "GET_GIFT_CONGRATS_FAILED"
export const GET_GIFT_BUY_PENDING = "GET_GIFT_BUY_PENDING"
export const GET_GIFT_BUY_SUCCESS = "GET_GIFT_BUY_SUCCESS"
export const GET_GIFT_BUY_FAILED = "GET_GIFT_BUY_FAILED"
export const GET_GIFT_CLAIM_PENDING = "GET_GIFT_CLAIM_PENDING"
export const GET_GIFT_CLAIM_SUCCESS = "GET_GIFT_CLAIM_SUCCESS"
export const GET_GIFT_CLAIM_FAILED = "GET_GIFT_CLAIM_FAILED"
export const GET_GIFT_OPTIONS_PENDING = "GET_GIFT_OPTIONS_PENDING"
export const GET_GIFT_OPTIONS_SUCCESS = "GET_GIFT_OPTIONS_SUCCESS"
export const GET_GIFT_OPTIONS_FAILED = "GET_GIFT_OPTIONS_FAILED"
export const VALIDATE_GIFT_SUCCESS = "VALIDATE_GIFT_SUCCESS"
export const SAVE_CLAIM_CODE = "SAVE_CLAIM_CODE"

// Promo Code
export const FETCH_PROMO_CODE_PAGE_SUCCESS = "FETCH_PROMO_CODE_PAGE_SUCCESS"
export const FETCH_PROMO_CODE_PAGE_ERROR = "FETCH_PROMO_CODE_PAGE_ERROR"
export const RESET_PROMO_CODE = "RESET_PROMO_CODE"
export const SET_PRODUCT = "SET_PRODUCT"
export const VALIDATE_PROMO_CODE_SUCCESS = "VALIDATE_PROMO_CODE_SUCCESS"

// API Actions
export const API_ACTION_ERROR = "API_ACTION_ERROR"
export const API_ACTION_SUCCESS = "API_ACTION_SUCCESS"

export const ADD_FEEDBACK = "ADD_FEEDBACK"
export const DELETE_FEEDBACK = "DELETE_FEEDBACK"

// Unsubscribe
export const GET_UNSUBSCRIBE_DATA_PENDING = "GET_UNSUBSCRIBE_DATA_PENDING"
export const GET_UNSUBSCRIBE_DATA_SUCCESS = "GET_UNSUBSCRIBE_DATA_SUCCESS"
export const GET_UNSUBSCRIBE_DATA_FAILED = "GET_UNSUBSCRIBE_DATA_FAILED"

// Animation
export const SHOW_ANIMATION = "SHOW_ANIMATION"
export const RESET_ANIMATION = "RESET_ANIMATION"

// DSP Related
export const DSP_PAYMENT_PENDING = "DSP_PAYMENT_PENDING"
export const DSP_PAYMENT_SUCCESS = "DSP_PAYMENT_SUCCESS"
export const DSP_PAYMENT_ERROR = "DSP_PAYMENT_ERROR"
export const SET_DSP_PASSWORD_ERROR = "SET_DSP_PASSWORD_ERROR"
export const SET_DSP_PAYMENT_FAILED = "SET_DSP_PAYMENT_FAILED"

// PURGE BOOKMARKS
export const GET_PURGE_BOOKMARK_DATA_PENDING = "GET_PURGE_BOOKMARK_DATA_PENDING"
export const GET_PURGE_BOOKMARK_DATA_SUCCESS = "GET_PURGE_BOOKMARK_DATA_SUCCESS"
export const GET_PURGE_BOOKMARK_DATA_FAILED = "GET_PURGE_BOOKMARK_DATA_FAILED"

// OPEN EUROPE
export const GET_OPEN_EUROPE_PENDING = "GET_OPEN_EUROPE_PENDING"
export const GET_OPEN_EUROPE_SUCCESS = "GET_OPEN_EUROPE_SUCCESS"
export const GET_OPEN_EUROPE_FAILED = "GET_OPEN_EUROPE_FAILED"
