import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"

const Button = ({
  color,
  value = "My Button",
  subtitle = null,
  onClick,
  disabled = false,
  customStyle,
  autoFocus,
  dataTest,
  outerTitle,
  icon,
  type = "button",
}) => {
  const content = (
    <button
      type={type}
      autoFocus={autoFocus}
      className={classNames(styles.button, customStyle, color)}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      <div
        className={classNames({
          [styles.bold]: subtitle,
          [styles.value]: Boolean(icon),
        })}
      >
        {icon}
        {value}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </button>
  )

  // The direct parent of a Button usually has the flex display property,
  // in case of outerTitle we need a wrapper to not break this
  return outerTitle ? (
    <div>
      <div className={styles.outerTitle}>{outerTitle}</div>
      {content}
    </div>
  ) : (
    content
  )
}

Button.colors = {
  black: styles.black,
  greyFull: styles.greyFull,
  greyDarkFull: styles.greyDarkFull,
  greyIron: styles.greyIron,
  red: styles.red,
  redFull: styles.redFull,
  yellowFull: styles.yellowFull,
  white: styles.white,
  primary: styles.yellowFull,
  secondary: styles.greyFull,
  borderless: styles.borderless,
  // new styles
  button_primary_outer_top: styles.yellowFull,
  button_primary: styles.yellowFull,
  button_secondary: styles.blueShark,
  button_link: styles.link,
}

Button.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  subtitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.string,
  autoFocus: PropTypes.bool,
  dataTest: PropTypes.string,
  outerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
}

export default Button
