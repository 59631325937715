import {
  GET_ACCOUNT_PENDING,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED,
  POST_ACCOUNT_PENDING,
  POST_ACCOUNT_FAILED,
  POST_ACCOUNT_SUCCESS,
} from "consts/actions"

import stateHelper from "helpers/state"

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_PENDING:
      return stateHelper.pending()

    case GET_ACCOUNT_SUCCESS:
      return { data: action.payload }

    case GET_ACCOUNT_FAILED:
      return { error: action.payload }

    case POST_ACCOUNT_PENDING:
      return stateHelper.pending()

    case POST_ACCOUNT_SUCCESS:
      return { data: action.payload }

    case POST_ACCOUNT_FAILED:
      return { error: action.payload }

    default:
      return state
  }
}
