import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"

import Feedback from "./feedback"

import { deleteFeedback } from "actions/feedbacks"
import styles from "./index.css"

@connect(state => ({
  feedbacks: state.feedbacks.list,
}))
export default class Feedbacks extends Component {
  static propTypes = {
    feedbacks: PropTypes.array,
    dispatch: PropTypes.func,
    rootClass: PropTypes.string,
  }

  render() {
    if (this.props.feedbacks.length === 0) {
      return null
    }
    return (
      <div className={classNames(this.props.rootClass, styles.root)}>
        {this.props.feedbacks.map(feedback => {
          return <Feedback key={feedback._id} feedback={feedback} onClose={() => this.props.dispatch(deleteFeedback(feedback._id))} />
        })}
      </div>
    )
  }
}
