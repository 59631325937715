export default class References {
  constructor(data) {
    this.channels = data.channels
    this.categories = data.categories
    this.subCategories = data.subCategories
    this.tvBundles = data.tvBundles
  }

  _findById(type, id) {
    if (!this[type]) {
      return {}
    }

    const item = this[type].find(t => t.id == id)

    return item ? item : {}
  }

  _findBySlug(type, slug) {
    if (!this[type]) {
      return {}
    }

    const item = this[type].find(t => t.slug == slug || t.slug_seo == slug)

    return item ? item : {}
  }

  getCategory(id) {
    return this._findById("categories", id)
  }

  getCategoryBySlug(slug) {
    return this._findBySlug("categories", slug)
  }

  getSubCategory(id) {
    const subCategoryId = id.split("_")[id.split("_").length - 1]
    return this._findById("subCategories", subCategoryId)
  }

  getSubCategoryBySlug(slug) {
    return this._findBySlug("subCategories", slug)
  }

  getChannelById(id) {
    return this.channels.find(c => c.getId() == id)
  }

  getChannelBySlug(slug) {
    return this.channels.find(c => c.getSlug() === slug)
  }

  getTvBundles() {
    return this.tvBundles
  }

  getOrderedChannels(id = undefined) {
    const ids = {}
    const bundles = id ? [this.getTvBundle(id)] : this.getTvBundles()

    return bundles
      .reduce((acc, bundle) => {
        return acc.concat(bundle ? bundle.channels : [])
      }, [])
      .filter(channel => !channel.isVirtual())
      .filter(x => {
        ids[x.data.id] = !ids[x.data.id] ? 1 : ids[x.data.id] + 1
        return ids[x.data.id] <= 1
      })
      .sort((a, b) => (this.channels || []).findIndex(x => x.data.id === a.data.id) - this.channels.findIndex(x => x.data.id === b.data.id))
  }

  getTvBundle(id) {
    return this.tvBundles.find(t => t.id == id)
  }

  isChannelCharged(channelId) {
    if (!this.getTvBundles().length) {
      return
    }

    let isCharged = true

    this.getTvBundles().forEach(b => {
      if (b.channel_ids && b.channel_ids.indexOf(channelId) !== -1 && !b.is_charged && !b.view_is_disabled) {
        isCharged = false
      }
    })

    return isCharged
  }
}
