import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import abTesting from "./abTesting"
import account from "./account"
import animation from "./animation"
import appSettings from "./appSettings"
import bookmarksDelete from "./bookmarksDelete"
import bookmarksRecord from "./bookmarksRecord"
import bookmarksScheduled from "./bookmarksScheduled"
import categories from "./categories"
import channels from "./channels"
import devices from "./devices"
import download from "./download"
import dspSub from "./dspSub"
import episodes from "./episodes"
import feedbacks from "./feedbacks"
import flash from "./flash"
import forms from "reducers/forms"
import gift from "./gift"
import link from "./link"
import modal from "./modal"
import mtvPay from "./mtvPay"
import navigator from "./navigator"
import notifications from "./notifications"
import OAuthSession from "./OAuthSession"
import offers from "./offers"
import openEurope from "./openEurope"
import parentalControl from "./parentalControl"
import person from "./person"
import privacy from "./privacy"
import productBookmarkConstraints from "./productBookmarkConstraints"
import productFree from "./productFree"
import products from "./products"
import programs from "./programs"
import promoCode from "./promoCode"
import recordMetrics from "./recordMetrics"
import references from "./references"
import scheduledMetrics from "./scheduledMetrics"
import seo from "./seo"
import seoMenu from "./seoMenu"
import strapi from "./strapi"
import serverError from "./serverError"
import session from "./session"
import signup from "./signup"
import subscriptions from "./subscriptions"
import ui from "./ui"
import user from "./user"
import winback from "./winback"
import appReferences from "./appReferences"
import newNotifications from "./newNotifications"
import genres from "./genres"

export default history => {
  return combineReducers({
    router: connectRouter(history),
    account,
    animation,
    appSettings,
    bookmarksDelete,
    bookmarksRecord,
    bookmarksScheduled,
    categories,
    channels,
    devices,
    download,
    dspSub,
    episodes,
    feedbacks,
    flash,
    abTesting,
    forms,
    gift,
    link,
    modal,
    mtvPay,
    navigator,
    newNotifications,
    notifications,
    OAuthSession,
    offers,
    openEurope,
    parentalControl,
    person,
    privacy,
    productBookmarkConstraints,
    productFree,
    products,
    programs,
    promoCode,
    recordMetrics,
    references,
    appReferences,
    scheduledMetrics,
    seo,
    seoMenu,
    strapi,
    serverError,
    session,
    signup,
    subscriptions,
    ui,
    user,
    winback,
    genres,
  })
}
