import { get, uniq, unset } from "lodash"

/**
 * Clean references' response to remove useless data
 *
 * Unused image type for channels:
 *   logo_player_loading
 *   channel_header
 *   header_channel
 *   atv_poster
 *   atv_topshelf
 *   logo_light
 *     medium
 *     large
 *   logo_dark
 *     large
 *   logo_background
 *     medium
 *   logo_overlay
 *     small
 *     medium
 *
 * Unused image size for channels:
 *   source
 *   large
 *
 * @param {Object} response - API response
 * @param {Object} state - Redux state
 * @return {Object} - Cleaned response
 */
export default function cleanReferences(response, state = {}) {
  if (!response || response.error) {
    return response
  }

  let authorizedChannels = []

  if (state.seo && state.seo.settings) {
    // Remove useless channels
    state.seo.settings.tv_bundles_visible.forEach(bundle => {
      authorizedChannels = authorizedChannels.concat(
        get(
          response.body.tvbundles.find(b => b.id == bundle),
          "channel_ids",
          []
        )
      )
    })

    authorizedChannels = authorizedChannels.filter(channel => channel !== "248")
    authorizedChannels = uniq(authorizedChannels)

    response.body.channels = response.body.channels.map(channel => {
      return {
        ...channel,
        is_commercialized: authorizedChannels.includes(channel.id),
      }
    })
    // Remove useless tvbundles
    response.body.tvbundles = response.body.tvbundles.filter(b => state.seo.settings.tv_bundles_visible.indexOf(b.id) !== -1)
  }

  // Remove useless images from channels
  response.body.channels = response.body.channels.map(channel => {
    if (channel.image_bundle) {
      unset(channel, "image_bundle.logo_player_loading")
      unset(channel, "image_bundle.channel_header")
      unset(channel, "image_bundle.header_channel")
      unset(channel, "image_bundle.atv_poster")
      unset(channel, "image_bundle.atv_topshelf")
      unset(channel, "image_bundle.logo_dark_cropped")
      unset(channel, "image_bundle.logo_light_cropped")

      Object.keys(channel.image_bundle).forEach(key => {
        unset(channel, `image_bundle.${key}.source`)
      })

      unset(channel, "image_bundle.logo_light.large")

      unset(channel, "image_bundle.logo_dark.large")

      unset(channel, "image_bundle.logo_background.medium")

      unset(channel, "image_bundle.logo_overlay.small")
      unset(channel, "image_bundle.logo_overlay.medium")
    }

    unset(channel, "asset_rights")

    return channel
  })

  return response
}
