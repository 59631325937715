import { get } from "lodash"

import FormatterHelper from "helpers/formatter"

import routes from "consts/routes"
import { LOCALE } from "consts/locale"

export default class ChannelModel {
  constructor(data, references) {
    this.data = data
    this.references = references
  }

  isValid() {
    return !!this.data.id
  }

  isVirtual() {
    return this.data.is_virtual
  }

  getId() {
    return this.data.id
  }

  getTitle() {
    return this.data.title
  }

  getSlug() {
    return encodeURIComponent(this.data.slug_seo)
  }

  getSrcSet(color = "dark") {
    const smallImage = this.data.image_bundle[`logo_${color}`]?.small?.url || ""
    const mediumImage = this.data.image_bundle[`logo_${color}`]?.medium?.url || ""
    const largeImage = this.data.image_bundle[`logo_${color}`]?.large?.url || ""
    const sourceImage = this.data.image_bundle[`logo_${color}`]?.source?.url || ""

    const srcSet = []
    if (smallImage) {
      srcSet.push(`${smallImage} 80w`)
    }

    /**
     * ! a lot of channels don't have medium images in light
     */
    if (mediumImage && color === "dark") {
      srcSet.push(`${mediumImage} 150w`)
      srcSet.push(`${mediumImage} 80w 2x`)
    }

    if (largeImage) {
      srcSet.push(`${largeImage} 300w`)
      srcSet.push(`${largeImage} 150w 2x`)
    }

    if (sourceImage) {
      srcSet.push(`${sourceImage} 1200w`)
      srcSet.push(`${sourceImage} 600w x2`)
    }

    return srcSet.join(", ")
  }

  getImageUrl(size = "small", color = "dark") {
    try {
      return this.data.image_bundle[`logo_${color}`][size].url
    } catch (e) {
      return ""
    }
  }

  getOverlayUrl(size = "small") {
    try {
      return this.data.image_bundle.overlay[size].url
    } catch (e) {
      return ""
    }
  }

  getBundles() {
    if (!this.bundles) {
      this.bundles = get(this.references, "tvbundles", [])
        .filter(b => b.channel_ids)
        .filter(b => b.channel_ids.indexOf(this.getId()) !== -1)
    }

    return this.bundles
  }

  isAvailableInFreeBundles(availableBundles = []) {
    return !!this.getBundles()
      .filter(b => availableBundles.indexOf(b.id) !== -1)
      .filter(b => !b.is_charged).length
  }

  getUrl() {
    return FormatterHelper.basic(routes.channel, { locale: LOCALE, channelId: this.getId(), channelSlug: this.getSlug() })
  }

  getReplayUrl() {
    return FormatterHelper.basic(routes.channelReplay, { locale: LOCALE, channelId: this.getId(), channelSlug: this.getSlug() })
  }

  hasReplay() {
    return this.data.has_replay
  }

  isCommercialized() {
    return this.data.is_commercialized
  }
}
