import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classnames from "classnames"
import { throttle } from "lodash"

import RouterSwitch from "components/RouterSwitch/view"
import SEOHeader from "containers/SEOHeader"
import Page500 from "components/Page500"

import { getReferences } from "actions/references"
import { getSEOMenu } from "actions/seoMenu"
import { allowClientRender, hideSEONav, scroll, setWindowHeight, catchError } from "actions/ui"
import { getSEOConfig } from "actions/seo"

import { selectQuery } from "selectors/location"

import styles from "./index.css"
import { selectProgram } from "../../selectors/programs"
import Footer from "components/Footer"

@connect((state, ownProps) => {
  const query = selectQuery(state)

  const selectorsParams = {
    location: ownProps.location,
    params: ownProps.match.params,
    query,
  }

  return {
    appError: state.ui.appError,
    app404Error: state.ui.app404Error,
    displayCookieBanner: state.privacy.displayCookieBanner,
    params: ownProps.match.params,
    query,
    serverError: state.serverError,
    seoNavVisible: state.ui.seoNavVisible,
    program: selectProgram(state, selectorsParams),
    currentPath: ownProps.match.path,
  }
})
export default class SEOWrapper extends Component {
  static propTypes = {
    appError: PropTypes.bool,
    app404Error: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    displayCookieBanner: PropTypes.bool,
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    serverError: PropTypes.bool,
    seoNavVisible: PropTypes.bool,
    program: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired,
  }

  static fetchData(dispatch) {
    return [dispatch(getReferences()), dispatch(getSEOConfig()), dispatch(getSEOMenu())]
  }

  closeMenu = () => {
    this.props.dispatch(hideSEONav())
  }

  watchScroll = () => {
    this.props.dispatch(scroll(window.scrollY))
  }

  watchResize = () => {
    this.props.dispatch(setWindowHeight(window.innerHeight))
  }

  constructor(props) {
    super(props)
    this.watchScroll = throttle(this.watchScroll, 200)
    this.watchResize = throttle(this.watchResize, 200)
  }

  componentDidMount() {
    if (!global.serverRendered) {
      this.constructor.fetchData(this.props.dispatch)
    }

    window.addEventListener("scroll", this.watchScroll)
    window.addEventListener("resize", this.watchResize)
    this.props.dispatch(allowClientRender())
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.watchScroll)
    window.removeEventListener("resize", this.watchResize)
  }

  componentDidCatch() {
    this.props.dispatch(catchError())
  }

  render() {
    const { location, appError, app404Error, seoNavVisible, serverError } = this.props

    const displayError = serverError || appError

    return displayError ? (
      <Page500 />
    ) : (
      <>
        <div className={styles.root} onClick={this.closeMenu}>
          <div>
            {!app404Error && <SEOHeader location={location} />}
            <div className={classnames({ [styles.hiddenContent]: seoNavVisible })}>
              <RouterSwitch groupName="SEORoutes" />
            </div>
          </div>
          <Footer isDarkTheme width={1120} />
        </div>
      </>
    )
  }
}
